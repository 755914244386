<template>
  <div class="battery">
    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="remainingViolations < yellow_from" style="margin-right: 10px">
      <path d="M14.4287 27.6686L12.3365 25.702C4.90575 18.7445 0 14.1409 0 8.52419C0 3.92059 3.49174 0.330078 7.93577 0.330078C10.4464 0.330078 12.856 1.53685 14.4287 3.42894C16.0014 1.53685 18.411 0.330078 20.9216 0.330078C25.3656 0.330078 28.8574 3.92059 28.8574 8.52419C28.8574 14.1409 23.9516 18.7445 16.5208 25.702L14.4287 27.6686Z" fill="url(#paint0_linear_8408_8774)"/>
      <defs>
        <linearGradient id="paint0_linear_8408_8774" x1="14.4287" y1="0.330078" x2="14.4287" y2="27.6686" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF6060"/>
          <stop offset="1" stop-color="#EC1010"/>
        </linearGradient>
      </defs>
    </svg>

    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="remainingViolations >= yellow_from && remainingViolations <= yellow_to" style="margin-right: 10px">
      <path d="M14.4287 27.6686L12.3365 25.702C4.90575 18.7445 0 14.1409 0 8.52419C0 3.92059 3.49174 0.330078 7.93577 0.330078C10.4464 0.330078 12.856 1.53685 14.4287 3.42894C16.0014 1.53685 18.411 0.330078 20.9216 0.330078C25.3656 0.330078 28.8574 3.92059 28.8574 8.52419C28.8574 14.1409 23.9516 18.7445 16.5208 25.702L14.4287 27.6686Z" fill="url(#paint0_linear_8380_7564)"/>
      <defs>
        <linearGradient id="paint0_linear_8380_7564" x1="14.4287" y1="0.330078" x2="14.4287" y2="27.6686" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFEC60"/>
          <stop offset="1" stop-color="#EC9410"/>
        </linearGradient>
      </defs>
    </svg>
    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="remainingViolations > yellow_to" style="margin-right: 10px">
      <path d="M14.4287 27.6686L12.3365 25.702C4.90575 18.7445 0 14.1409 0 8.52419C0 3.92059 3.49174 0.330078 7.93577 0.330078C10.4464 0.330078 12.856 1.53685 14.4287 3.42894C16.0014 1.53685 18.411 0.330078 20.9216 0.330078C25.3656 0.330078 28.8574 3.92059 28.8574 8.52419C28.8574 14.1409 23.9516 18.7445 16.5208 25.702L14.4287 27.6686Z" fill="url(#paint0_linear_8408_8775)"/>
      <defs>
        <linearGradient id="paint0_linear_8408_8775" x1="14.4287" y1="0.330078" x2="14.4287" y2="27.6686" gradientUnits="userSpaceOnUse">
          <stop offset="0.121" stop-color="#6AFF60"/>
          <stop offset="1" stop-color="#018837"/>
        </linearGradient>
      </defs>
    </svg>
    <div
        v-for="(section, index) in totalSections"
        :key="section"
        class="section"
        :style="getSectionStyle(section)"
    ></div>
    <div class="violations-label" style="margin-left: 5px" :style="{ color: fillColor }">
      {{remainingViolations}}/{{totalSections}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Battery',
  props: {
    totalSections: {
      type: Number,
      required: true
    },
    remainingViolations: {
      type: Number,
      required: true
    },
    yellow_from: {
      type: Number,
      required: true
    },
    yellow_to: {
      type: Number,
      required: true
    }
  },
  computed: {
    fillColor() {
      const v = this.remainingViolations;
      if (v >= 0 && v < this.yellow_from) {
        return '#E24C4B';
      } else if (v >= this.yellow_from && v <= this.yellow_to) {
        return '#FAD059';
      } else {
        return '#018837';
      }
    }
  },
  methods: {
    getSectionStyle(index) {
      return {
        backgroundColor: index <= this.remainingViolations ? this.fillColor : '#D9D9D9'
      };
    }
  }
};
</script>

<style scoped>
.battery {
  display: flex;
}
.section {
  width: 7px;
  height: 28px;
}
</style>
