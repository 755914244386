<template>
  <div class="view-home">
    <div class="container">
      <div class="main-wrapper-desktop">
        <div class="main-wrapper-left">
          <div class="main-wrapper-header">
            <div class="main-wrapper-header-left">
              <p class="subtitle" v-if="test_lang == 'ru'">
                {{ (Number(currentSubjectIndex) || 0) + 1 }} предмет из {{ (quizData && quizData.subjects && quizData.subjects.length) || 0 }}
              </p>
              <p class="subtitle" v-if="test_lang == 'kz'">
                {{ (quizData && quizData.subjects && quizData.subjects.length) || 0 }} пәннің {{ (Number(currentSubjectIndex) || 0) + 1 }}
              </p>
              <p class="title">
                {{this.currentQuizSubject.subject_data[`name_${test_lang}`]}}
              </p>
            </div>
            <div class="main-wrapper-header-timer">
              <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_8097_21928)">
                  <path d="M11.8808 0.765625C5.80572 0.765625 0.880859 5.69048 0.880859 11.7656C0.880859 17.8407 5.80572 22.7656 11.8808 22.7656C17.956 22.7656 22.8808 17.8407 22.8808 11.7656C22.8744 5.6932 17.9533 0.772116 11.8808 0.765625ZM11.8808 21.1942C6.67358 21.1942 2.45227 16.9729 2.45227 11.7656C2.45227 6.55835 6.67358 2.33704 11.8808 2.33704C17.0881 2.33704 21.3094 6.55835 21.3094 11.7656C21.3038 16.9706 17.0858 21.1885 11.8808 21.1942Z" fill="#3377FF"/>
                  <path d="M11.8781 4.69531C11.4441 4.69531 11.0923 5.04709 11.0923 5.48104V10.9811H5.59237C5.15842 10.9811 4.80664 11.3328 4.80664 11.7668C4.80664 12.2007 5.15842 12.5525 5.59237 12.5525H11.8781C12.312 12.5525 12.6638 12.2007 12.6638 11.7667V5.48104C12.6638 5.04709 12.312 4.69531 11.8781 4.69531Z" fill="#3377FF"/>
                </g>
                <defs>
                  <clipPath id="clip0_8097_21928">
                    <rect width="22" height="22" fill="white" transform="translate(0.880859 0.765625)"/>
                  </clipPath>
                </defs>
              </svg>
              {{ countdown }}
            </div>
          </div>

          <div class="question-block">
            <div class="question-block-title">
              {{$t('test_page_1')}} №{{Number(currentIndex) + 1}}
            </div>
            <div class="question-content ck-content question-body" v-viewer="{movable: false}">
              <div v-katex:auto v-html="question_title">
              </div>
            </div>
            <div v-if="question_file">
              <audio :src="`https://elbasyacademy.kz/uploads${question_file}`" controls></audio>
            </div>
            <form class="row answers-block">
              <!--
                Одиночный (radio) тип вопроса
              -->
              <div v-if="currentQuestion.question.answer_type_id == 1"
                   class="w-100">
                <!--                <el-radio v-for="(answer) in question_answers" :key="answer.id" v-model="selected_answer_id[0]"-->
                <!--                          :label="answer.id" border class="">-->
                <!--                  <div v-katex:auto v-html="answer['text_' + test_lang]"></div>-->
                <!--                </el-radio>-->
                <div class="radio-group">
                  <el-radio v-for="(answer) in question_answers" label="1" border :key="answer.id"
                            v-model="selected_answer_id[0]" :label="answer.id">
                    <div v-katex:auto v-html="answer['text_' + test_lang]"></div>
                  </el-radio>
                </div>
              </div>
              <!--
                Множественный (checkbox) тип вопроса
              -->
              <div v-if="currentQuestion.question.answer_type_id == 2"
                   class="w-100">
                <div class="checkbox-group">
                  <el-checkbox-group v-model="selected_answer_id">
                    <el-checkbox
                        v-for="answer in question_answers"
                        :key="answer.id"
                        :label="answer.id"
                        border
                    >
                      <div v-katex:auto v-html="answer['text_' + test_lang]"></div>
                    </el-checkbox>
                  </el-checkbox-group>

                </div>
              </div>
            </form>

            <div class="question-btns-block">
              <button class="question-prev-btn" @click="prevQuestion" v-if="!isFirstQuestion">
                {{$t('test_page_2')}}
              </button>
              <button class="question-next-btn" @click="goToNextQuestion">
                {{$t('test_page_3')}}
              </button>
            </div>
<!--            <button @click="captureAndCompare">Сравнить фото</button>-->

          </div>
        </div>
        <div class="main-wrapper-right">
          <div class="violation-counter-block">
            <div class="violation-counter">
              {{$t('test_page_4')}}
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"
                   @mouseenter="showTooltipProctoring = true"
                   @mouseleave="showTooltipProctoring = false"
              >
                <path
                    d="M11.8557 22.1412C17.1813 22.1412 21.4986 17.8239 21.4986 12.4983C21.4986 7.17272 17.1813 2.85547 11.8557 2.85547C6.53014 2.85547 2.21289 7.17272 2.21289 12.4983C2.21289 17.8239 6.53014 22.1412 11.8557 22.1412Z"
                    stroke="#3377FF" stroke-width="1.92857" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.8574 16.3571V12.5" stroke="#3377FF" stroke-width="1.92857" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M11.8574 8.64258H11.8674" stroke="#3377FF" stroke-width="1.92857" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
              <div v-if="showTooltipProctoring" class="tooltip-block">
                <p>
                  {{$t('test_page_5')}}
                </p>
                <p>
                  {{$t('test_page_6')}}
                </p>
                <p>
                  {{$t('test_page_7')}}
                </p>
                <p style="color: #018837">
                  • {{$t('test_page_8')}} (0–{{quizData.violation_count.yellow_from}}).
                </p>
                <p style="color: #FAD059">
                  • {{$t('test_page_9')}} ({{quizData.violation_count.yellow_from}}–{{quizData.violation_count.yellow_to}}).
                </p>
                <p style="color: #E24C4B">
                  • {{$t('test_page_10')}} ({{quizData.violation_count.yellow_to}}<), тест завершается.
                </p>
                <p>
                  {{$t('test_page_11')}}
                </p>
              </div>

            </div>
            <div class="violation-indicator">
<!--              <BatteryIndicator :violations="violationsNumber"/>-->
<!--              {{violationsNumber}}/15-->
              <NewBatteryIndicator :totalSections="Number(quizData.violation_count.red_from)"
                                   :yellow_from="Number(quizData.violation_count.yellow_from)"
                                   :yellow_to="Number(quizData.violation_count.yellow_to)"
                                   :remainingViolations="violationsNumber"
              />
            </div>
          </div>
          <div class="navigation">
            <div class="card">
              <h3>{{ $t('testing.navigation') }}</h3>
              <div class="navigation-wrap clearfix">
<!--                <a @click="!strict_mode ? goTo(key) : null" class="cursor-pointer" v-for="(key) in questions.length"-->
<!--                   :key="`question_${key}`"-->
<!--                   :class="{'checked': saved_answers[key], 'active': ($route.params.id === key.toString()) }">{{-->
<!--                    key-->
<!--                  }}</a>-->
                <button
                    v-for="(q, i) in questions"
                    :key="q.id"
                    @click="goToQuestion(i)"
                    :class="{'checked': saved_answers[i] && saved_answers[i].length !== 0 }"
                    class="cursor-pointer navigation-item"
                >
                  {{ Number(i) + 1 }}
                </button>

              </div>
<!--              <div class="text-center">-->
<!--                <a @click="dialogLogicalThinkingTestCompleted=true" class="btn btn-danger">{{ $t('testing.finish-button') }}</a>-->
<!--              </div>-->
            </div>
          </div>

          <div>
            <div class="camera-live-block-desktop">
              <div v-if="!hasAccessCamera">
                <img src="/images/no-camera.svg" alt=""/>
              </div>
              <div v-else class="camera-live-container-desktop">
                <video width="236" height="131" ref="videoElement3" autoplay playsinline muted disablePictureInPicture></video>
                <canvas width="236" height="131" ref="canvas"></canvas>
                <div class="soundbar-block soundbar-block-desktop">
                  <div class="soundbar">
                    <div
                        v-for="(bar, index) in bars"
                        :key="index"
                        class="bar"
                        :style="{ height: bar.height + '%', backgroundColor: bar.color }"
                    ></div>
                  </div>
                  <!--                  <p v-if="!hasMicrophoneAccess" class="no-sound">Звука нет</p>-->
                  <!--                  <p v-else v-html="noiseMessage">{{ noiseMessage }}</p>-->
                </div>
              </div>
            </div>
            <div class="text-center">
              <a @click="dialogLogicalThinkingTestCompleted=true" class="btn btn-danger finish-test-btn">{{ $t('testing.finish-button') }}</a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="main-wrapper-mobile">
      <div class="test-page-header-mobile">
        <button class="test-page-btn-close-mobile">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6L6 18M6 6L18 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
        <div class="test-page-desc-mobile">
          <div>
            <h2>
              {{ this.currentQuizSubject.subject_data[`name_${test_lang}`] }}
            </h2>
            <h3>
              {{ Number(currentQuizIndex) + 1 }} предмет из {{ quizData.subjects.length }}
            </h3>
            <div class="test-timer">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.30706 14.2137C11.5692 14.2137 14.2137 11.5692 14.2137 8.30706C14.2137 5.0449 11.5692 2.40039 8.30706 2.40039C5.0449 2.40039 2.40039 5.0449 2.40039 8.30706C2.40039 11.5692 5.0449 14.2137 8.30706 14.2137Z"
                    stroke="url(#paint0_linear_8380_7485)" stroke-width="1.2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.30664 5.08008V8.30674L10.2933 9.25341" stroke="url(#paint1_linear_8380_7485)"
                      stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <defs>
                  <linearGradient id="paint0_linear_8380_7485" x1="-1.83666" y1="-1.79334" x2="26.7833" y2="16.6262"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#3550DC"/>
                    <stop offset="1" stop-color="#27E9F7"/>
                  </linearGradient>
                  <linearGradient id="paint1_linear_8380_7485" x1="7.59409" y1="3.59854" x2="13.8167" y2="5.50498"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#3550DC"/>
                    <stop offset="1" stop-color="#27E9F7"/>
                  </linearGradient>
                </defs>
              </svg>
              <!--            <span>40:20</span>-->
              <span>{{ countdown }}</span>
            </div>
            <div class="violation-counter">
              Счетчик нарушений
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_8380_7495)">
                  <path
                      d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                      stroke="#F0F0F0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9 12V9" stroke="#F0F0F0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9 6H9.0075" stroke="#F0F0F0" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_8380_7495">
                    <rect width="18" height="18" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div>
              <BatteryIndicator :violations="violationsNumber"/>

            </div>
          </div>
          <div>
            <div class="camera-live-block" v-if="isShowCameraLiveBlockMobile">
              <div v-if="!hasAccessCamera">
                <img src="/images/no-camera.svg" alt=""/>
              </div>
              <div v-else class="camera-live-container">
                <video width="92" height="183" ref="videoElement3" autoplay playsinline muted disablePictureInPicture></video>
                <canvas width="92" height="183" ref="canvas"></canvas>
                <div class="soundbar-block soundbar-block-mobile">
                  <div class="soundbar">
                    <div
                        v-for="(bar, index) in bars"
                        :key="index"
                        class="bar"
                        :style="{ height: bar.height + '%', backgroundColor: bar.color }"
                    ></div>
                  </div>
<!--                  <p v-if="!hasMicrophoneAccess" class="no-sound">Звука нет</p>-->
<!--                  <p v-else v-html="noiseMessage">{{ noiseMessage }}</p>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container questions-wrap questions-wrap-mobile">
        <!-- Пагинация (шары) -->
<!--        <div class="pagination">-->
<!--          <button-->
<!--              v-for="(q, i) in visibleQuestions"-->
<!--              :key="q.id"-->
<!--              @click="goToQuestion(q.realIndex)"-->
<!--              :class="['question-circle', { active: q.realIndex === currentIndex }]"-->
<!--          >-->
<!--            {{ q.displayIndex }}-->
<!--          </button>-->
<!--        </div>-->
        <div class="pagination-container">
          <div class="pagination">
            <button
                v-for="(q, i) in visibleQuestions"
                :key="q.id"
                @click="goToQuestion(q.realIndex)"
                :class="['question-circle', { active: q.realIndex === currentIndex }]"
            >
              {{ q.displayIndex }}
            </button>
          </div>

          <!-- Прогресс-бар под активным шаром -->
          <div class="progress-bar-test">
            <div
                class="progress-indicator"
                :style="{ transform: `translateX(${activeCirclePosition}px)` }"
            ></div>
          </div>
        </div>
        <div class="question-content ck-content" v-viewer="{movable: false}">
          <div v-katex:auto v-html="question_title">
          </div>
        </div>
        <div v-if="question_file">
          <audio :src="`https://elbasyacademy.kz/uploads${question_file}`" controls></audio>
        </div>
        <form class="row answers-block">
          <!--
            Одиночный (radio) тип вопроса
          -->
          <div v-if="currentQuestion.question.answer_type_id == 1"
               class="w-100">
            <!--                <el-radio v-for="(answer) in question_answers" :key="answer.id" v-model="selected_answer_id[0]"-->
            <!--                          :label="answer.id" border class="">-->
            <!--                  <div v-katex:auto v-html="answer['text_' + test_lang]"></div>-->
            <!--                </el-radio>-->
            <div class="radio-group">
              <el-radio v-for="(answer) in question_answers" label="1" border :key="answer.id"
                        v-model="selected_answer_id[0]" :label="answer.id">
                <div v-katex:auto v-html="answer['text_' + test_lang]"></div>
              </el-radio>
            </div>
          </div>
          <!--
            Множественный (checkbox) тип вопроса
          -->
          <div v-if="currentQuestion.question.answer_type_id == 2"
               class="w-100">
            <div class="checkbox-group">
              <el-checkbox-group v-model="selected_answer_id">
                <el-checkbox
                    v-for="answer in question_answers"
                    :key="answer.id"
                    :label="answer.id"
                    border
                >
                  <div v-katex:auto v-html="answer['text_' + test_lang]"></div>
                </el-checkbox>
              </el-checkbox-group>

            </div>
          </div>
          <!--
            Тип вопроса: правда/ложь
          -->
          <div v-if="answer_type == 3"
               class="w-100">
            <div class="radio-group">
              <el-radio v-for="(answer) in question_answers" label="1" border :key="answer.id"
                        v-model="selected_answer_id[0]" :label="answer.id">
                <div v-katex:auto v-html="answer['text_' + test_lang]"></div>
              </el-radio>
            </div>
          </div>
          <!--
            Свободный ответ: textarea
          -->
          <div v-if="answer_type == 4" class="col-12 ck-content">
            <el-form ref="form" class="col-12 col-md-auto">
              <el-form-item label="Ваш ответ:">
                <el-input type="textarea" :value="textarea" v-model="textarea"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div v-if="answer_type == 6" class="col-12 ck-content">
            <div ref="strWithInputs" v-html="question_answers[0][`text_${test_lang}`]"></div>
          </div>
          <div v-if="answer_type == 7" class="col-12 ck-content">
            <div ref="strWithSelect" v-html="questions[question_id - 1][`text_${test_lang}`]"></div>
          </div>

          <div v-if="answer_type == 11" class="col-12 ck-content">
            <el-form ref="form" class="col-12 col-md-auto">
              <el-form-item label="Ваш ответ:">
                <el-input type="textarea" :value="textarea" v-model="textarea"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </form>
<!--        <div v-if="!strict_mode" class="col-12 col-md-4 order-1">-->
<!--          <div class="navigation">-->
<!--            <div class="card">-->
<!--              <h3>{{ $t('testing.navigation') }}</h3>-->
<!--              <div class="navigation-wrap clearfix">-->
<!--                <a @click="!strict_mode ? goTo(key) : null" class="cursor-pointer" v-for="(key) in questions.length"-->
<!--                   :key="`question_${key}`"-->
<!--                   :class="{'checked': saved_answers[key], 'active': ($route.params.id === key.toString()) }">{{-->
<!--                    key-->
<!--                  }}</a>-->
<!--              </div>-->
<!--              <div class="text-center">-->
<!--                <a @click="openModal" class="btn btn-danger">{{ $t('testing.finish-button') }}</a>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="question-btns-block">
          <button class="question-prev-btn" @click="prevQuestion">
            Пред вопрос
          </button>
          <button class="question-next-btn" @click="goToNextQuestion">
            След вопрос
          </button>
        </div>
<!--        <button @click="captureAndCompare">Сравнить фото</button>-->
      </div>
    </div>
    <el-dialog
        :visible.sync="dialogLogicalThinkingTestCompleted"
        :width="modalWidthTestCompleted"
        class="modal-dialog-test-completed"
    >
      <template #title>
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF"/>
          <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8"/>
          <path
              d="M23.5 28L26.5 31L32.5 25M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z"
              stroke="#039855" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </template>

      <div class="modal-dialog-title" v-if="test_lang == 'ru'">
        Предмет {{this.currentQuizSubject.subject_data[`name_${test_lang}`]}} успешно завершен!
      </div>
      <div class="modal-dialog-title" v-if="test_lang == 'kz'">
        {{this.currentQuizSubject.subject_data[`name_${test_lang}`]}} пәні сәтті аяқталды!
      </div>
      <div v-if="currentSubjectIndex != quizData.subjects.length - 1">
        <p class="modal-dialog-text" v-if="test_lang == 'kz'">
          Келесі пәнге өткеннен кейін, {{ this.currentQuizSubject.subject_data[`name_${test_lang}`] }} пәніне қайта
          оралу мүмкін емес.
        </p>
        <p class="modal-dialog-text" v-if="test_lang == 'ru'">
          После перехода к следующему предмету, вы не сможете вернуться к предмету
          {{ this.currentQuizSubject.subject_data[`name_${test_lang}`] }}.
        </p>
      </div>
      <div v-if="currentSubjectIndex == quizData.subjects.length - 1">
        <p class="modal-dialog-text" v-if="test_lang == 'kz'">
          "Тестті аяқтау" батырмасын басқаннан кейін сіз {{ this.currentQuizSubject.subject_data[`name_${test_lang}`] }} пәніне орала алмайсыз
        </p>
        <p class="modal-dialog-text" v-if="test_lang == 'ru'">
          "После нажатия кнопки "Завершить тест", вы не сможете вернуться к предмету {{ this.currentQuizSubject.subject_data[`name_${test_lang}`] }}"
        </p>
      </div>

      <div
          v-for="(subject, index) in quizData.subjects"
          :key="subject.id"
          :class="index <= currentQuizIndex ? 'modal-dialog-test-passed' : 'modal-dialog-test-next'"
      >
        <svg
            width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
          <g style="mix-blend-mode:multiply">
            <rect
                width="32" height="32" rx="16"
                :fill="index <= currentQuizIndex ? '#039855' : '#0055FF'"
                :fill-opacity="index <= currentQuizIndex ? '0.11' : '0.16'"
            />
            <path
                v-if="index <= currentQuizIndex"
                d="M9.33398 17.6664L15.7621 20.8805C15.8496 20.9242 15.8933 20.9461 15.9392 20.9547C15.9798 20.9623 16.0215 20.9623 16.0621 20.9547C16.108 20.9461 16.1517 20.9242 16.2392 20.8805L22.6673 17.6664M9.33398 14.3331L15.7621 11.119C15.8496 11.0752 15.8933 11.0534 15.9392 11.0448C15.9798 11.0372 16.0215 11.0372 16.0621 11.0448C16.108 11.0534 16.1517 11.0752 16.2392 11.119L22.6673 14.3331L16.2392 17.5471C16.1517 17.5909 16.108 17.6127 16.0621 17.6213C16.0215 17.6289 15.9798 17.6289 15.9392 17.6213C15.8933 17.6127 15.8496 17.5909 15.7621 17.5471L9.33398 14.3331Z"
                stroke="#039855" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"
            />
            <path
                v-else
                d="M16.6672 9.33398L10.7295 16.4592C10.497 16.7383 10.3807 16.8778 10.3789 16.9956C10.3774 17.0981 10.423 17.1955 10.5027 17.2599C10.5944 17.334 10.776 17.334 11.1392 17.334H16.0006L15.3339 22.6673L21.2716 15.5421C21.5041 15.263 21.6204 15.1235 21.6222 15.0057C21.6237 14.9032 21.5781 14.8058 21.4984 14.7414C21.4067 14.6673 21.2251 14.6673 20.8619 14.6673H16.0006L16.6672 9.33398Z"
                stroke="#3377FF" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"
            />
          </g>
        </svg>

        {{ subject[`name_${test_lang}`] }}

        <svg
            v-if="index <= currentQuizIndex"
            width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
            class="modal-dialog-test-passed-tick"
        >
          <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#039855"/>
          <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#039855"/>
          <path d="M11.3327 5.5L6.74935 10.0833L4.66602 8" stroke="white" stroke-width="1.66667"
                stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>

      <h2>
        {{$t('test_page_12')}}
      </h2>

<!--      <div class="modal-dialog-test-photos-list">-->
<!--        <img src="/images/modal-test-img.png" :width="modalPhotoWidth" height="100" alt=""/>-->
<!--        <img src="/images/modal-test-img.png" :width="modalPhotoWidth" height="100" alt=""/>-->
<!--        <img src="/images/modal-test-img.png" :width="modalPhotoWidth" height="100" alt=""/>-->
<!--      </div>-->

      <button class="modal-dialog-test-completed-next" @click="nextSubject">
        <span v-if="currentSubjectIndex == quizData.subjects.length - 1">{{$t('test_page_13')}}</span>
        <span v-else>{{$t('test_page_14')}}</span>
      </button>

    </el-dialog>

    <el-dialog
        :visible="false"
        width="400px"
        class="modal-dialog-exit"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
    >
      <template #title>
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2"/>
          <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" stroke-width="8"/>
          <path d="M25.5 26C27.433 26 29 24.433 29 22.5C29 20.567 27.433 19 25.5 19C23.567 19 22 20.567 22 22.5C22 24.433 23.567 26 25.5 26Z" stroke="#FF5257" stroke-width="2" stroke-linejoin="round"/>
          <path d="M32.5 31.5L36.5 35.5" stroke="#FF5257" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M32.5 35.5L36.5 31.5" stroke="#FF5257" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M29.5 30H25.4C23.1598 30 22.0397 30 21.1841 30.436C20.4314 30.8195 19.8195 31.4314 19.436 32.184C19 33.0397 19 34.1598 19 36.4V37H29.5" stroke="#FF5257" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </template>

      <div class="modal-dialog-title">
        {{$t('modal_1')}}
      </div>
      <p class="modal-dialog-text">
        {{$t('modal_2')}}
      </p>
      <p class="modal-dialog-text">
        {{$t('modal_3')}}
      </p>

      <div class="d-flex justify-content-between">
        <button class="modal-btn-test">
          {{$t('modal_4')}}
        </button>
        <button class="modal-btn-continue" @click="dialogNoFace=false" :disabled="!isFaceDetected" :class="{ disabled: !isFaceDetected  }">
          {{$t('modal_5')}}
        </button>
      </div>

    </el-dialog>

    <el-dialog
        :visible.sync="dialogManyFaces"
        width="400px"
        class="modal-dialog-exit"
    >
      <template #title>
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2"/>
          <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" stroke-width="8"/>
          <path d="M28 26C29.933 26 31.5 24.433 31.5 22.5C31.5 20.567 29.933 19 28 19C26.067 19 24.5 20.567 24.5 22.5C24.5 24.433 26.067 26 28 26Z" stroke="#FF5257" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M22 19.627C21.0932 20.2594 20.5 21.3102 20.5 22.4996C20.5 23.7728 21.1798 24.8872 22.1962 25.4996" stroke="#FF5257" stroke-opacity="0.65" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M34 19.627C34.9068 20.2594 35.5 21.3102 35.5 22.4996C35.5 23.689 34.9068 24.7398 34 25.3723" stroke="#FF5257" stroke-opacity="0.65" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M22 36V37H34V36C34 34.1363 34 33.2043 33.6955 32.4692C33.2895 31.4891 32.5109 30.7104 31.5308 30.3045C30.7957 30 29.8637 30 28 30C26.1363 30 25.2043 30 24.4692 30.3045C23.4891 30.7104 22.7105 31.4891 22.3045 32.4692C22 33.2043 22 34.1363 22 36Z" stroke="#FF5257" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M38.0005 36.9996V36.3996C38.0005 34.1594 38.0005 33.0393 37.5645 32.1836C37.181 31.431 36.5691 30.819 35.8164 30.4355" stroke="#FF5257" stroke-opacity="0.65" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M18 36.9996V36.3996C18 34.1594 18 33.0393 18.436 32.1836C18.8195 31.431 19.4314 30.819 20.184 30.4355" stroke="#FF5257" stroke-opacity="0.65" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </template>

      <div class="modal-dialog-title">
        {{$t('modal_11')}}
      </div>
      <p class="modal-dialog-text">
        {{$t('modal_12')}}
      </p>
      <p class="modal-dialog-text">
        {{$t('modal_13')}}
      </p>

      <div class="d-flex justify-content-between">
        <button class="modal-btn-test">
          {{$t('modal_4')}}
        </button>
        <button class="modal-btn-continue" @click="dialogManyFaces=false">
          {{$t('modal_5')}}
        </button>
      </div>

    </el-dialog>
    <el-dialog
        :visible.sync="dialogHighNoise"
        width="400px"
        class="modal-dialog-exit"
    >
      <template #title>
        <svg width="69" height="53" viewBox="0 0 69 53" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
          <rect y="32.1699" width="6.12467" height="20.5687" rx="3.06234" fill="#E24C4B"/>
          <rect x="7.7207" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
          <rect x="7.7207" y="38.6992" width="6.12467" height="14.0386" rx="3.06234" fill="#E24C4B"/>
          <rect x="15.4414" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
          <rect x="62" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
          <rect x="15.4414" y="12.7676" width="6.12467" height="39.9707" rx="3.06234" fill="#E24C4B"/>
          <rect x="62" y="12.7676" width="6.12467" height="39.9707" rx="3.06234" fill="#E24C4B"/>
          <rect x="23.1602" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
          <rect x="23.1602" y="24.6758" width="6.12467" height="28.0631" rx="3.06234" fill="#E24C4B"/>
          <rect x="30.7012" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
          <rect x="30.7012" y="32.1699" width="6.12467" height="20.5687" rx="3.06234" fill="#E24C4B"/>
          <rect x="38.5098" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
          <rect x="38.5098" y="28.1152" width="6.12467" height="24.6231" rx="3.06234" fill="#E24C4B"/>
          <rect x="46.1426" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
          <rect x="54" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
          <rect x="46.1426" y="32.1699" width="6.12467" height="20.5687" rx="3.06234" fill="#E24C4B"/>
          <rect x="54" y="32.1699" width="6.12467" height="20.5687" rx="3.06234" fill="#E24C4B"/>
        </svg>
      </template>

      <div class="modal-dialog-title">
        {{$t('modal_9')}}
      </div>
      <p class="modal-dialog-text">
        {{$t('modal_10')}}
      </p>

      <div class="d-flex justify-content-between">
        <button class="modal-btn-test">
          {{$t('modal_4')}}
        </button>
        <button class="modal-btn-continue" @click="dialogHighNoise=false">
          {{$t('modal_5')}}
        </button>
      </div>

    </el-dialog>
    <el-dialog
        :visible="!hasAccessCamera"
        width="400px"
        class="modal-dialog-exit"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
    >
      <template #title>
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2"/>
          <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" stroke-width="8"/>
          <path d="M25.5 26C27.433 26 29 24.433 29 22.5C29 20.567 27.433 19 25.5 19C23.567 19 22 20.567 22 22.5C22 24.433 23.567 26 25.5 26Z" stroke="#FF5257" stroke-width="2" stroke-linejoin="round"/>
          <path d="M32.5 31.5L36.5 35.5" stroke="#FF5257" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M32.5 35.5L36.5 31.5" stroke="#FF5257" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M29.5 30H25.4C23.1598 30 22.0397 30 21.1841 30.436C20.4314 30.8195 19.8195 31.4314 19.436 32.184C19 33.0397 19 34.1598 19 36.4V37H29.5" stroke="#FF5257" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </template>

      <div class="modal-dialog-title">
        {{$t('modal_14')}}
      </div>
      <p class="modal-dialog-text">
        {{$t('modal_15')}}
      </p>

      <div class="d-flex justify-content-between">
        <button class="modal-btn-test">
          {{$t('modal_4')}}
        </button>
<!--        <button class="modal-btn-continue" @click="dialogNoFace=false" :disabled="!isFaceDetected">-->
<!--          Продолжить-->
<!--        </button>-->
      </div>

    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import BatteryIndicator from "@/components/BatteryIndicator.vue";
import 'katex/dist/katex.min.css';
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import * as faceapi from 'face-api.js';
import { EventBus } from "@/eventBus";
import { mapState } from "vuex";
import NewBatteryIndicator from "@/components/NewBatteryIndicator.vue";

Vue.use(VueViewer)
export default {
  name: "AboutSubject",
  components: {NewBatteryIndicator, BatteryIndicator},
  data() {
    return {
      showTooltipProctoring: false,
      hasMicrophoneAccess: false,
      isShowCameraLiveBlockMobile: false,
      isQuiet: false,
      dialogHighNoise: false,
      dialogManyFaces : false,
      dialogNoFace : false,
      modelsLoaded: false,
      faceDetected: null,
      lastDetectionTime: Date.now(),
      bars: Array(10).fill({ height: 100, color: 'gray' }),
      hasAccessCamera: true,
      timerInterval: null,
      countdown: 0,
      dialogLogicalThinkingTestCompleted: false,
      modalPhotoWidth: 129,
      questions: [],
      answer_type: 0,
      questionId: this.$route.params.id,
      question_answers: [],
      test_lang: 'ru',
      strict_mode: false,
      question_title: '',
      question_file: null,
      selected_answer_id: [],
      circleSize: 42,
      answers_word: [],
      quiz_token: '',
      textarea: '',
      saved_answers: [],
      modalWidthTestCompleted: '510px',
      currentQuizSubject: {
        subject_data: {
          name_ru: '',
          name_kz: '',
        }
      },
      quizData: {
        subjects: [],
        violation_count: {
          green_to : 0,
          red_from : 0,
          yellow_from : 0,
          yellow_to : 0,
        }
      },
      windowUnloadType: window.innerWidth < 678 ? 'pagehide' : 'beforeunload',
      audioContext: null,
      analyser: null,
      microphone: null,
      lowNoiseLevel : this.$store.state.proctoring.lowNoiseLevel,
      highNoiseLevel : this.$store.state.proctoring.highNoiseLevel,
      faceInterval: null,
      isFaceDetected: false,
      currentSubjectIndex: 0
    }
  },
  methods: {
    updateStreamCamera(src) {
      this.$store.commit('updateStreamCamera', src);
    },
    updateStreamAudio(src) {
      this.$store.commit('updateStreamAudio', src);
    },
    getCameraConstraints() {
      if (/Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        // Смартфон или планшет
        return { video: { facingMode: "user" } }; // Фронтальная камера
      } else {
        // Компьютер
        return { video: true }; // Любая доступная камера
      }
    },
    async requestAccessCamera() {
      try {

        this.streamCamera = await navigator.mediaDevices.getUserMedia(this.getCameraConstraints());
        this.hasAccessCamera = true;

        setTimeout(() => {
          const videoElement = this.$refs.videoElement3;
          console.log('this.$refs.videoElement3;', this.$refs.videoElement3)
          if (videoElement) {
            videoElement.srcObject = this.streamCamera;
            this.updateStreamCamera(this.streamCamera)
            console.log('videoElement.srcObject', videoElement.srcObject)
          } else {
            console.error('Видео элемент не найден');
          }
        }, 500)

      } catch (error) {
        console.error('Ошибка доступа к камере:', error);

        Vue.toastr({
          message: 'Ошибка',
          description: 'Ошибка доступа к камере. Проверьте камеру.',
          type: 'error'
        })
        this.hasAccessCamera = false;
      }
    },
    startMicrophoneAccessCheck() {
      if (this.accessCheckInterval) clearInterval(this.accessCheckInterval);

      this.accessCheckInterval = setInterval(async () => {
        if (this.microphoneStream && !this.microphoneStream.active) {
          // Поток больше не активен
          this.hasMicrophoneAccess = false;
          console.warn('Поток микрофона больше не активен');
          clearInterval(this.accessCheckInterval);
          cancelAnimationFrame(this.animationFrameId);
          this.bars = Array(10).fill({ height: 100, color: 'gray' })
        } else {
          // Дополнительно проверяем через Permissions API
          const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
          if (permissionStatus.state !== 'granted') {
            this.hasMicrophoneAccess = false;
            console.warn('Доступ к микрофону был отозван через настройки браузера');
            clearInterval(this.accessCheckInterval);
            cancelAnimationFrame(this.animationFrameId);
            this.bars = Array(10).fill({ height: 100, color: 'gray' })
          }
        }
      }, 2000);
    },
    async requestAccess() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.microphoneStream = stream;
        this.updateStreamAudio(stream)
        this.hasMicrophoneAccess = true;
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        this.microphone = this.audioContext.createMediaStreamSource(stream);
        this.analyser = this.audioContext.createAnalyser();
        this.microphone.connect(this.analyser);
        this.analyser.fftSize = 256;

        console.log('stream111', stream)
        this.startMicrophoneAccessCheck();

        this.startVisualizing();
      } catch (error) {
        console.error('Ошибка доступа к микрофону:', error);

        Vue.toastr({
          message: 'Ошибка',
          description: 'Ошибка доступа к микрофону. Проверьте микрофон.',
          type: 'error'
        })

        this.hasMicrophoneAccess = false;
      }
    },
    async captureAndCompare() {
      const video = this.$refs.videoElement3;
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      const userDescriptor = await this.getDescriptorFromBase64(canvas.toDataURL());

      console.log('this.storedDescriptor', this.storedDescriptor)

      if (!userDescriptor) {
        console.log("Лицо пользователя не найдено!");
        return;
      }
      const distance = faceapi.euclideanDistance(userDescriptor, this.storedDescriptor);

      if (distance < 0.6) {
        console.log("Лица совпадают!");
      } else {
        console.log("Лица не совпадают!");
        this.sendEvent('face_match')
      }
    },
    async getDescriptorFromBase64(base64String) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = base64String;
        img.onload = async () => {
          const detections = await faceapi.detectSingleFace(img)
              .withFaceLandmarks()
              .withFaceDescriptor();

          if (!detections) {
            console.warn(" Лицо не найдено на изображении!");
            return null;
          }
          resolve(detections.descriptor);
        };
        img.onerror = reject;
      });
    },
    sendEvent(text) {
      EventBus.$emit("customEvent", text);
    },
    async loadModels() {
      try {
        const MODEL_URL = '/models';
        await faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL);
        await faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL);
        await faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL);
        await faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL);
        this.modelsLoaded = true;
      } catch (error) {
        console.error('Ошибка загрузки моделей:', error);
      }
    },
    async detectFaces() {
      if (!this.modelsLoaded) return;

      const video = this.$refs.videoElement3;
      const canvas = this.$refs.canvas;

      // video.width = video.videoWidth || 640;
      // video.height = video.videoHeight || 480;
      // canvas.width = video.videoWidth;
      // canvas.height = video.videoHeight;

      const displaySize = { width: video.width, height: video.height };
      faceapi.matchDimensions(canvas, displaySize);

      let faceDetected = null;
      let lastDetectionTime = Date.now();

      const draw = async () => {
        const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
            .withFaceLandmarks();

        const resizedDetections = faceapi.resizeResults(detections, displaySize);
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);

        if (resizedDetections.length > 1) {
          if (faceDetected !== "multiple") {
            faceDetected = "multiple";
            this.dialogManyFaces = true
            this.sendEvent('multiple_faces')
          }
          lastDetectionTime = Date.now();
        } else if (resizedDetections.length === 1) {
          if (faceDetected !== "single") {

            faceDetected = "single";
            this.isFaceDetected = true
            if (this.dialogNoFace) {
              console.log("Лицо появилось - закрываем модальное окно!");
            }
          }
          lastDetectionTime = Date.now();
        } else {
          if (Date.now() - lastDetectionTime > 500) {
            if (faceDetected !== "none") {

              faceDetected = "none";
              this.isFaceDetected = false
              this.dialogNoFace = true
              // this.sendEvent('no_face')
            }
          }
        }

        faceapi.draw.drawDetections(canvas, resizedDetections);
        faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);

        requestAnimationFrame(draw);
      };

      draw();
    }
    ,
    getRandomNumber(normalized) {
      const numbers = [1, 3, 7, 9, 10];
      const randomIndex = Math.floor(Math.random() * numbers.length);

      if (normalized <= 0.3) {
        return numbers[randomIndex] * 3;
      } else if (normalized <= 0.7) {
        return numbers[randomIndex] * 10;
      } else {
        return numbers[randomIndex] * 12;
      }
    },
    startVisualizing() {
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      this.microphone = this.audioContext.createMediaStreamSource(this.getSrcAudioStream);
      this.analyser = this.audioContext.createAnalyser();
      this.microphone.connect(this.analyser);
      this.analyser.fftSize = 256;
      let wasHighNoiseTriggered = false;
      const dataArray = new Uint8Array(this.analyser.frequencyBinCount);

      const updateBars = () => {
        this.analyser.getByteFrequencyData(dataArray);
        const average = dataArray.reduce((a, b) => a + b, 0) / dataArray.length;
        const normalized = Math.min(average / 100, 1);

        this.noiseLevel = 20 * Math.log10(average / 255) + 90;

        let color = 'gray';
        if (this.noiseLevel <= this.lowNoiseLevel) { // Низкий уровень шума (Тихо)
          color = 'green';
          this.isQuiet = true;
          wasHighNoiseTriggered = false;
        } else if (this.noiseLevel > this.lowNoiseLevel && this.noiseLevel <= this.highNoiseLevel) { // Средний уровень шума (Шумно)
          color = 'yellow';
          this.isQuiet = false;
          // if (!this.dialogHighNoise) {
          //   this.dialogHighNoise = true;
          // }
          // if (!wasHighNoiseTriggered) {
          //   this.dialogHighNoise = true; // Открываем диалог только один раз
          //   wasHighNoiseTriggered = true; // Устанавливаем флаг
          // }
        } else if (this.noiseLevel > this.highNoiseLevel) { // Высокий уровень шума (Очень шумно)
          color = 'red';
          this.isQuiet = false;
          // if (!this.dialogHighNoise) {
          //   this.dialogHighNoise = true;
          // }
          // if (!wasHighNoiseTriggered) {
          //   this.dialogHighNoise = true; // Открываем диалог только один раз
          //   wasHighNoiseTriggered = true; // Устанавливаем флаг
          // }
        }

        // this.bars = this.bars.map(() => ({
        //   height: Math.max(10, normalized * 100),
        //   color: color,
        // }));

        this.bars = this.bars.map(() => ({
          height: this.getRandomNumber(normalized),
          color: color,
        }));

        this.animationFrameId = requestAnimationFrame(updateBars);
      };

      updateBars();
    },
    async nextSubject() {
      const res = await this.saveAnswer();
      clearInterval(this.timerInterval)
      localStorage.removeItem('timer')
      const quizdata = JSON.parse(localStorage.getItem('quiz-data')) || { subjects: [] };
      const currentQuizSubject = JSON.parse(localStorage.getItem('current-quiz-subject')) || {};

      if (!quizdata.subjects.length || !currentQuizSubject.subject_data?.id) {
        console.warn("Отсутствуют данные о тесте или текущем предмете.");
        return;
      }

      const currentSubjectIndex = quizdata.subjects.findIndex(item => item.id === currentQuizSubject.subject_data.id);
      console.log('currentSubjectIndex', currentSubjectIndex)

      const subject_id = currentQuizSubject.subject_data.id
      console.log('subject_id', subject_id)
      const quizToken = localStorage.getItem('quiz-token');

      console.log('quizToken', quizToken)
      console.log('quizdata', quizdata)

      this.$http.get(API_ROOT + `/api/quiz/${quizToken}/${subject_id}/finish`)
          .then(res => {
            console.log('res55', res)
          })

      if (currentSubjectIndex >= 0 && currentSubjectIndex < quizdata.subjects.length - 1) {
        const nextId = quizdata.subjects[currentSubjectIndex + 1].id
        this.$router.push({ name: 'about-subject', params: { id: nextId } });
      } else {
        console.warn("Следующего предмета нет или это последний предмет.");
        // this.$router.push({ name: 'test-results'});
        localStorage.removeItem('violationsNumber');
        localStorage.removeItem("savedPhoto")

        if(this.quizData.display_results) {
          window.location.href = this.$router.resolve({ name: 'test-results' }).href;
        } else {
          window.location.href = 'https://1000bala.elumiti.kz/testing'
        }
      }
    }
    ,
    async goToQuestion(index) {
      if (index >= 0 && index < this.questions.length) {
        const res = await this.saveAnswer();
        this.$router.push({ params: { id: this.questions[index].id } });
      }
    },
    async prevQuestion() {
      if (!this.isFirstQuestion) {

        const res = await this.saveAnswer();
        const prevId = this.questions[this.currentIndex - 1].id;
        this.$router.push({ params: { id: prevId } });
      }
    },
    async goToNextQuestion() {
      if (!this.isLastQuestion) {

        const res = await this.saveAnswer();

        const nextId = this.questions[this.currentIndex + 1].id;
        this.selected_answer_id = [];
        this.$router.push({ params: { id: nextId } });
        this.question_title = this.currentQuestion.question[`text_${this.test_lang}`]
      } else {
        this.dialogLogicalThinkingTestCompleted = true
      }

    },
    async saveAnswer() {
      if (this.selected_answer_id !== '') {
        const answer = (Array.isArray(this.selected_answer_id)) ? this.selected_answer_id : [this.selected_answer_id];
        const answerData = answer.filter(item => item != null)
        const questionId = (this.currentQuestion.question_id) ?? 0;
        let params = {};

        console.log('questionId', questionId)
        console.log('this.currentQuestion', this.currentQuestion)

        params = {
          question_id: questionId,
          answer_id: answerData,
          answers: answerData,
          answer_type_id: this.answer_type
        };

        // if (this.currentQuestion.question.answer_type_id == 1) {
        //   if (answer.length) {
        //     params = {
        //       question_id: questionId,
        //       answer_id: answer,
        //       answers: answer,
        //       answer_type_id: this.answer_type
        //     };
        //   }
        // } else {
        //   params = {
        //     question_id: questionId,
        //     answer_id: answerData,
        //     answers: answerData,
        //     answer_type_id: this.answer_type
        //   };
        // }
        // if (this.textarea !== '') {
        //   params = {
        //     question_id: questionId,
        //     answers: [this.textarea],
        //     answer_type_id: this.answer_type
        //   }
        // }
        // if (this.answers_word.length) {
        //   params = {
        //     question_id: questionId,
        //     answer_id: this.questions[this.question_id - 1].answers[0].id,
        //     answers: this.answers_word,
        //     answer_type_id: this.answer_type
        //   }
        // }

        if (Object.getOwnPropertyNames(params).length !== 0) {
          this.btnLoading = true
          try {
            const response = await this.$http.post(API_ROOT + '/api/user-answer/' + this.quiz_token, params)
            if (response && response.status >= 200 && response.status < 300) {
              this.sendRemaningTimer()
              let savedAnswers = [];
              if (localStorage.getItem('saved-answers')) {
                savedAnswers = JSON.parse(localStorage.getItem('saved-answers'));
              }
              if (this.answer_type === 1) {
                savedAnswers[this.currentIndex] = params.answer_id;
              }
              if (this.answer_type === 2) {
                savedAnswers[this.currentIndex] = params.answer_id;
              }
              // if (this.answer_type === 3) {
              //   savedAnswers[this.$route.params.id] = params.answer_id;
              // }
              // if (this.answer_type === 4) {
              //   savedAnswers[this.$route.params.id] = params.answers;
              // }
              // if (this.answer_type === 6) {
              //   savedAnswers[this.$route.params.id] = this.answers_word
              // }
              // if (this.answer_type === 7) {
              //   savedAnswers[this.$route.params.id] = this.answers_word
              // }
              // if (this.answer_type === 11) {
              //   savedAnswers[this.$route.params.id] = params.answers;
              // }
              localStorage.setItem('saved-answers', JSON.stringify(savedAnswers));
              console.log('savedAnswers111', savedAnswers)
              this.updateCheckedAnswers();
            }
            return response
          } catch (error) {
            console.log(error.status==404)
            if (error.status==404){
              window.removeEventListener(this.windowUnloadType, this.beforeUnloadRequest)
              // window.location.href = '/completed';

              if(this.quizData.display_results) {
                window.location.href = this.$router.resolve({ name: 'test-results' }).href;
              } else {
                window.location.href = 'https://1000bala.elumiti.kz/testing'
              }
            }
            if (!navigator.onLine) {
              Vue.toastr({
                message: 'Проверьте интернет-соединение.',
                description: 'Ответ может не сохраниться.',
                type: 'warning'
              })
            }
            return error
          } finally {
            this.btnLoading = false
          }
        }
      }
    },
    updateQuestionTitle() {
      if (this.currentQuestion) {
        this.question_title = this.currentQuestion.question[`text_${this.test_lang}`] || '';
      } else {
        this.question_title = '';
      }
    },
    updateAnswers() {
      if (this.currentQuestion) {
        this.question_answers = this.currentQuestion.question.answers
      }
    },
    exit() {
      this.nextSubject()
    },
    sendRemaningTimer() {
      const subjectId = this.currentQuizSubject.subject_data.id
      this.$http.get(`${window.API_ROOT}/api/quiz/${this.quiz_token}/${subjectId}/user-left`, {
        params: {
          user_quiz_id: localStorage.getItem('user_quiz_id'),
          remaining_time: localStorage.getItem('timer')
        }
      })
    },
    startTimer(duration) {
      let timer = duration, minutes, seconds;
      this.timerInterval = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.countdown = minutes + ":" + seconds;

        let savedTimer = (parseInt(minutes) * 60) + parseInt(seconds);
        localStorage.setItem('timer', savedTimer);

        if (--timer < 1) {
          this.saveAnswer();
          this.exit();
          // timer = duration;
        }
      }, 1000);
    },
    updateCheckedAnswers: function () {
      /*
       * Обновляем переменную saved_answers чтобы кнопки
       * отвеченных вопросов меняли цвет как в дизайне
       */
      let res = {};
      if (localStorage.getItem('saved-answers')) {
        let savedAnswers = localStorage.getItem('saved-answers');
        res = JSON.parse(savedAnswers)
        this.saved_answers = res;
        console.log(1234566, this.saved_answers)

        if (this.saved_answers[this.currentIndex]) {
          if (this.currentQuestion.question.answer_type_id == 1) {
            this.selected_answer_id[0] = this.saved_answers[this.currentIndex][0]
            console.log('this.selected_answer_id[0]', this.selected_answer_id[0])

            // Vue.toastr({
            //   message: 'Внимание!',
            //   description: 'Ответ сохранен',
            //   type: 'success',
            // });
          }

          if (this.currentQuestion.question.answer_type_id == 2) {
            this.selected_answer_id = this.saved_answers[this.currentIndex]

            // Vue.toastr({
            //   message: 'Внимание!',
            //   description: 'Ответ сохранен',
            //   type: 'success',
            // });
          }
        }

        /*
         * Заменяем заголовок модального окна
         * если есть ответ на последний вопрос
         */
        res.forEach((val, key) => {
          if (key == this.questions.length) {
            this.modalTitle = this.$t('testing.finish-modal-title')
          }
        })
      }
    },

  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(newId) {
        this.questionId = Number(newId);
        this.updateQuestionTitle();
        this.updateAnswers();
        this.updateCheckedAnswers()
      }
    },
  },
  computed: {
    ...mapState({
      violationsNumber: state => state.proctoring?.violations || 0,
    }),
    isMediaAvailable() {
      return this.$store.getters.isMediaAvailable;
    },
    referenceImageBase64() {
      return this.$store.state.proctoring.photo; // Эталонное фото в Base64
    },
    getSrcAudioStream() {
      return this.$store.state.proctoring.streamAudio;
    },
    getSrcStream() {
      return this.$store.state.proctoring.streamCamera;
    },
    activeCirclePosition() {
      const activeIndex = this.visibleQuestions.findIndex(q => q.realIndex === this.currentIndex);
      return activeIndex * this.circleSize;
    },
    visibleQuestions() {
      const total = this.questions.length;
      if (total <= 7) {
        return this.questions.map((q, i) => ({ ...q, realIndex: i, displayIndex: i + 1 }));
      }

      let start = Math.max(0, this.currentIndex - 3);
      let end = Math.min(total, start + 7);

      if (end === total) {
        start = end - 7;
      }

      return this.questions.slice(start, end).map((q, i) => ({
        ...q,
        realIndex: start + i,
        displayIndex: start + i + 1
      }));
    },
    isFirstQuestion() {
      return this.currentIndex == 0;
    },
    currentQuestion() {
      return this.questions.find(q => q.id == this.questionId) || { question: {} };
    },
    currentIndex() {
      const id = this.currentQuestion.question_id
      return this.questions.findIndex(q => q.question_id == id);
    },
    isLastQuestion() {
      return this.currentIndex === this.questions.length - 1;
    },
    currentQuizIndex() {
      return this.quizData.subjects.findIndex(item => item.id == this.currentQuizSubject.subject_data.id);
    },
  },
  mounted: async function () {
    if (window.innerWidth < 1000) {
        this.modalWidthTestCompleted = '100%'
        this.modalPhotoWidth = '31%'
    } else {
      this.modalWidthTestCompleted = '510px'
      this.modalPhotoWidth = '129'
    }

    // if (!this.getSrcAudioStream) {
    //   console.log('аудио нет')
    // }
    //
    // if (!this.getSrcStream) {
    //   console.log('видео нет')
    //   this.noCameraAccess = true
    // }

    console.log('this.getSrcStream123', this.getSrcStream)

    if(!this.getSrcStream) {
      await this.requestAccessCamera();
    }

    if(!this.getSrcAudioStream) {
      await this.requestAccess()
    }

    // await this.loadModels()

    if (window.innerWidth < 1000) {
      this.isShowCameraLiveBlockMobile = true
    }

    // this.faceInterval = setInterval(() => {
    //   console.log("Запуск сравнения лиц");
    //   this.captureAndCompare();
    // }, 300000);

    setTimeout(() => {
      this.startVisualizing()
      const videoElement3 = this.$refs.videoElement3;
      if (videoElement3) {

        const stream = this.getSrcStream;
        console.log('stream11111111', stream)
        if (stream) {
          videoElement3.srcObject = stream;

          // Отслеживаем, если камера отключится
          stream.getVideoTracks().forEach(track => {
            track.onended = () => {
              console.warn("Поток камеры прерван, запрашиваю заново...");
              this.requestAccessCamera();
            };
          });

        } else {
          console.error('Поток камеры отсутствует, пробую запросить доступ...');
          this.requestAccessCamera();
        }

        setTimeout(async () => {
          await this.loadModels()
          await this.detectFaces();
        },500)

      } else {
        console.error('Видео элемент не найден');
      }
    }, 500)

    this.quizData = JSON.parse(localStorage.getItem('quiz-data')) || { subjects: [] };

    console.log('this.quizData', this.quizData)

    let res;
    res = await this.$http.get(window.window.API_ROOT + `/api/selected/${localStorage.getItem('user_quiz_id')}/${localStorage.getItem('quiz-token')}`)

    this.currentQuizSubject = JSON.parse(localStorage.getItem('current-quiz-subject'))

    this.questions = JSON.parse(localStorage.getItem('current-quiz-subject')).questions;
    this.quiz_token = localStorage.getItem('quiz-token')
    this.answer_type = this.currentQuestion.question.answer_type_id
    this.question_answers = this.currentQuestion.question.answers

    console.log('this.questions', this.questions)
    console.log('this.questions', this.questions)

    const quizdata = JSON.parse(localStorage.getItem('quiz-data')) || { subjects: [] };
    const currentQuizSubject = JSON.parse(localStorage.getItem('current-quiz-subject')) || {};
    this.currentSubjectIndex = quizdata.subjects.findIndex(item => item.id === currentQuizSubject.subject_data.id);

    if (localStorage.getItem('test_lang')) {
      this.test_lang = localStorage.getItem('test_lang');
      this.$i18n.locale = this.test_lang;
    }

    // таймер
    let timer = 0;
    // this.countdown = ('0' + quiz.time).slice(-2) + ':00';
    // this.countdown = ('');
    timer = localStorage.getItem('timer');
    if (timer < 1) {
      this.exit()
      return
    }
    this.updateCheckedAnswers();
    this.startTimer(timer);

    this.question_title = this.currentQuestion.question[`text_${this.test_lang}`]

    const questions = JSON.parse(localStorage.getItem('current-quiz-subject')).questions;
    const savedAnswers = []

    for (let i = 0; i < questions.length; i++) {
      Object.keys(questions[i]).forEach(n => n !== 'question_id' && delete questions[i][n])
    }
    console.log('questions.length', questions)


    for (let x = 0; x < questions.length; x++) {
      console.log('res.body.data[questions[x].id]', res.body.data[questions[x].question_id])
      if (res.body.data[questions[x].question_id] != undefined) {
        if (res.body.data[questions[x].question_id].answer_id) {
          savedAnswers.push([res.body.data[questions[x].question_id].answer_id])
        } else {
          savedAnswers.push(res.body.data[questions[x].question_id].answer_words)
        }

      }
      else {
        savedAnswers.push(null)
      }

    }

    if(savedAnswers[0]==null){
      savedAnswers[0]=[null]
    }
    localStorage.setItem('saved-answers', JSON.stringify(savedAnswers))
    this.selected_answer_id = [];
    this.updateCheckedAnswers()

    // if (this.referenceImageBase64) {
    //   setTimeout(async () => {
    //     this.storedDescriptor = await this.getDescriptorFromBase64(this.referenceImageBase64);
    //     console.log('this.storedDescriptor', this.storedDescriptor)
    //     if (this.storedDescriptor) {
    //       console.log("Эталонное лицо сохранено");
    //     }
    //   },3000)
    //
    // }

  },
  beforeDestroy() {
    clearInterval(this.timerInterval)
    clearInterval(this.faceInterval);
    // window.removeEventListener(this.windowUnloadType, this.beforeUnloadRequest)
  },

}
</script>
<style >

canvas {
  position: absolute;
  top: 0;
  left: 0;
}

</style>

<style>

.tooltip-container {
  position: relative;
  display: inline-block;
  margin-left: 15px;
}

.triangle-icon {
  cursor: pointer;
}

.tooltip-block {
  position: absolute;
  top: 40px;
  left: -75px;
  width: 542px;
  background-color: #fff;
  padding: 25px 18px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 10;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #3A3A3C;
}


.tooltip-block.microphone {
  top: 40px;
  left: -220px;
}

.tooltip-block p {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #3A3A3C;
}


</style>
