<template>
  <div class="battery-indicator-block">
    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="violations <= 5">
      <path d="M14.4287 27.6686L12.3365 25.702C4.90575 18.7445 0 14.1409 0 8.52419C0 3.92059 3.49174 0.330078 7.93577 0.330078C10.4464 0.330078 12.856 1.53685 14.4287 3.42894C16.0014 1.53685 18.411 0.330078 20.9216 0.330078C25.3656 0.330078 28.8574 3.92059 28.8574 8.52419C28.8574 14.1409 23.9516 18.7445 16.5208 25.702L14.4287 27.6686Z" fill="url(#paint0_linear_8408_8774)"/>
      <defs>
        <linearGradient id="paint0_linear_8408_8774" x1="14.4287" y1="0.330078" x2="14.4287" y2="27.6686" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF6060"/>
          <stop offset="1" stop-color="#EC1010"/>
        </linearGradient>
      </defs>
    </svg>

    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="violations >= 6 && violations <= 10">
      <path d="M14.4287 27.6686L12.3365 25.702C4.90575 18.7445 0 14.1409 0 8.52419C0 3.92059 3.49174 0.330078 7.93577 0.330078C10.4464 0.330078 12.856 1.53685 14.4287 3.42894C16.0014 1.53685 18.411 0.330078 20.9216 0.330078C25.3656 0.330078 28.8574 3.92059 28.8574 8.52419C28.8574 14.1409 23.9516 18.7445 16.5208 25.702L14.4287 27.6686Z" fill="url(#paint0_linear_8380_7564)"/>
      <defs>
        <linearGradient id="paint0_linear_8380_7564" x1="14.4287" y1="0.330078" x2="14.4287" y2="27.6686" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFEC60"/>
          <stop offset="1" stop-color="#EC9410"/>
        </linearGradient>
      </defs>
    </svg>
    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="violations >= 11">
      <path d="M14.4287 27.6686L12.3365 25.702C4.90575 18.7445 0 14.1409 0 8.52419C0 3.92059 3.49174 0.330078 7.93577 0.330078C10.4464 0.330078 12.856 1.53685 14.4287 3.42894C16.0014 1.53685 18.411 0.330078 20.9216 0.330078C25.3656 0.330078 28.8574 3.92059 28.8574 8.52419C28.8574 14.1409 23.9516 18.7445 16.5208 25.702L14.4287 27.6686Z" fill="url(#paint0_linear_8408_8775)"/>
      <defs>
        <linearGradient id="paint0_linear_8408_8775" x1="14.4287" y1="0.330078" x2="14.4287" y2="27.6686" gradientUnits="userSpaceOnUse">
          <stop offset="0.121" stop-color="#6AFF60"/>
          <stop offset="1" stop-color="#018837"/>
        </linearGradient>
      </defs>
    </svg>

    <svg width="125" height="28" viewBox="0 0 125 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="violations==15">
      <rect x="124.377" y="4.11523" width="19.7712" height="10.4671" rx="3.88634" transform="rotate(90 124.377 4.11523)" fill="#018837"/>
      <rect x="111.91" width="28" height="6.07523" rx="3.03762" transform="rotate(90 111.91 0)" fill="#018837"/>
      <rect x="103.834" width="28" height="6.07523" rx="3.03762" transform="rotate(90 103.834 0)" fill="#018837"/>
      <rect x="95.7598" width="28" height="6.07523" rx="3.03762" transform="rotate(90 95.7598 0)" fill="#018837"/>
      <rect x="87.6836" width="28" height="6.07523" rx="3.03762" transform="rotate(90 87.6836 0)" fill="#018837"/>
      <rect x="79.6094" width="28" height="6.07523" rx="3.03762" transform="rotate(90 79.6094 0)" fill="#018837"/>
      <rect x="71.5332" width="28" height="6.07523" rx="3.03762" transform="rotate(90 71.5332 0)" fill="#018837"/>
      <rect x="63.459" width="28" height="6.07523" rx="3.03762" transform="rotate(90 63.459 0)" fill="#018837"/>
      <rect x="55.3828" width="28" height="6.07523" rx="3.03762" transform="rotate(90 55.3828 0)" fill="#018837"/>
      <rect x="47.3086" width="28" height="6.07523" rx="3.03762" transform="rotate(90 47.3086 0)" fill="#018837"/>
      <rect x="39.2324" width="28" height="6.07523" rx="3.03762" transform="rotate(90 39.2324 0)" fill="#018837"/>
      <rect x="31.1582" width="28" height="6.07523" rx="3.03762" transform="rotate(90 31.1582 0)" fill="#018837"/>
      <rect x="23.082" width="28" height="6.07523" rx="3.03762" transform="rotate(90 23.082 0)" fill="#018837"/>
      <rect x="15.0078" width="28" height="6.07523" rx="3.03762" transform="rotate(90 15.0078 0)" fill="#018837"/>
      <path d="M3.89403 -1.32778e-07C5.57165 -5.94469e-08 6.93164 1.35999 6.93164 3.03761L6.93164 24.9624C6.93164 26.64 5.57165 28 3.89402 28C2.2164 28 0.856409 26.64 0.856409 24.9624L0.85641 3.03761C0.85641 1.35999 2.2164 -2.0611e-07 3.89403 -1.32778e-07Z" fill="#018837"/>
    </svg>
    <svg width="125" height="28" viewBox="0 0 125 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="violations==14">
      <rect x="124.377" y="4.11523" width="19.7712" height="10.4671" rx="3.88634" transform="rotate(90 124.377 4.11523)" fill="#D9D9D9"/>
      <rect x="111.91" width="28" height="6.07523" rx="3.03762" transform="rotate(90 111.91 0)" fill="#018837"/>
      <rect x="103.834" width="28" height="6.07523" rx="3.03762" transform="rotate(90 103.834 0)" fill="#018837"/>
      <rect x="95.7598" width="28" height="6.07523" rx="3.03762" transform="rotate(90 95.7598 0)" fill="#018837"/>
      <rect x="87.6836" width="28" height="6.07523" rx="3.03762" transform="rotate(90 87.6836 0)" fill="#018837"/>
      <rect x="79.6094" width="28" height="6.07523" rx="3.03762" transform="rotate(90 79.6094 0)" fill="#018837"/>
      <rect x="71.5332" width="28" height="6.07523" rx="3.03762" transform="rotate(90 71.5332 0)" fill="#018837"/>
      <rect x="63.459" width="28" height="6.07523" rx="3.03762" transform="rotate(90 63.459 0)" fill="#018837"/>
      <rect x="55.3828" width="28" height="6.07523" rx="3.03762" transform="rotate(90 55.3828 0)" fill="#018837"/>
      <rect x="47.3086" width="28" height="6.07523" rx="3.03762" transform="rotate(90 47.3086 0)" fill="#018837"/>
      <rect x="39.2324" width="28" height="6.07523" rx="3.03762" transform="rotate(90 39.2324 0)" fill="#018837"/>
      <rect x="31.1582" width="28" height="6.07523" rx="3.03762" transform="rotate(90 31.1582 0)" fill="#018837"/>
      <rect x="23.082" width="28" height="6.07523" rx="3.03762" transform="rotate(90 23.082 0)" fill="#018837"/>
      <rect x="15.0078" width="28" height="6.07523" rx="3.03762" transform="rotate(90 15.0078 0)" fill="#018837"/>
      <path d="M3.89403 -1.32778e-07C5.57165 -5.94469e-08 6.93164 1.35999 6.93164 3.03761L6.93164 24.9624C6.93164 26.64 5.57165 28 3.89402 28C2.2164 28 0.856409 26.64 0.856409 24.9624L0.85641 3.03761C0.85641 1.35999 2.2164 -2.0611e-07 3.89403 -1.32778e-07Z" fill="#018837"/>
    </svg>
    <svg width="125" height="28" viewBox="0 0 125 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="violations==13">
      <rect x="124.377" y="4.11523" width="19.7712" height="10.4671" rx="3.88634" transform="rotate(90 124.377 4.11523)" fill="#D9D9D9"/>
      <rect x="111.91" width="28" height="6.07523" rx="3.03762" transform="rotate(90 111.91 0)" fill="#D9D9D9"/>
      <rect x="103.834" width="28" height="6.07523" rx="3.03762" transform="rotate(90 103.834 0)" fill="#018837"/>
      <rect x="95.7598" width="28" height="6.07523" rx="3.03762" transform="rotate(90 95.7598 0)" fill="#018837"/>
      <rect x="87.6836" width="28" height="6.07523" rx="3.03762" transform="rotate(90 87.6836 0)" fill="#018837"/>
      <rect x="79.6094" width="28" height="6.07523" rx="3.03762" transform="rotate(90 79.6094 0)" fill="#018837"/>
      <rect x="71.5332" width="28" height="6.07523" rx="3.03762" transform="rotate(90 71.5332 0)" fill="#018837"/>
      <rect x="63.459" width="28" height="6.07523" rx="3.03762" transform="rotate(90 63.459 0)" fill="#018837"/>
      <rect x="55.3828" width="28" height="6.07523" rx="3.03762" transform="rotate(90 55.3828 0)" fill="#018837"/>
      <rect x="47.3086" width="28" height="6.07523" rx="3.03762" transform="rotate(90 47.3086 0)" fill="#018837"/>
      <rect x="39.2324" width="28" height="6.07523" rx="3.03762" transform="rotate(90 39.2324 0)" fill="#018837"/>
      <rect x="31.1582" width="28" height="6.07523" rx="3.03762" transform="rotate(90 31.1582 0)" fill="#018837"/>
      <rect x="23.082" width="28" height="6.07523" rx="3.03762" transform="rotate(90 23.082 0)" fill="#018837"/>
      <rect x="15.0078" width="28" height="6.07523" rx="3.03762" transform="rotate(90 15.0078 0)" fill="#018837"/>
      <path d="M3.89403 -1.32778e-07C5.57165 -5.94469e-08 6.93164 1.35999 6.93164 3.03761L6.93164 24.9624C6.93164 26.64 5.57165 28 3.89402 28C2.2164 28 0.856409 26.64 0.856409 24.9624L0.85641 3.03761C0.85641 1.35999 2.2164 -2.0611e-07 3.89403 -1.32778e-07Z" fill="#018837"/>
    </svg>
    <svg width="125" height="28" viewBox="0 0 125 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="violations==12">
      <rect x="124.377" y="4.11523" width="19.7712" height="10.4671" rx="3.88634" transform="rotate(90 124.377 4.11523)" fill="#D9D9D9"/>
      <rect x="111.91" width="28" height="6.07523" rx="3.03762" transform="rotate(90 111.91 0)" fill="#D9D9D9"/>
      <rect x="103.834" width="28" height="6.07523" rx="3.03762" transform="rotate(90 103.834 0)" fill="#D9D9D9"/>
      <rect x="95.7598" width="28" height="6.07523" rx="3.03762" transform="rotate(90 95.7598 0)" fill="#018837"/>
      <rect x="87.6836" width="28" height="6.07523" rx="3.03762" transform="rotate(90 87.6836 0)" fill="#018837"/>
      <rect x="79.6094" width="28" height="6.07523" rx="3.03762" transform="rotate(90 79.6094 0)" fill="#018837"/>
      <rect x="71.5332" width="28" height="6.07523" rx="3.03762" transform="rotate(90 71.5332 0)" fill="#018837"/>
      <rect x="63.459" width="28" height="6.07523" rx="3.03762" transform="rotate(90 63.459 0)" fill="#018837"/>
      <rect x="55.3828" width="28" height="6.07523" rx="3.03762" transform="rotate(90 55.3828 0)" fill="#018837"/>
      <rect x="47.3086" width="28" height="6.07523" rx="3.03762" transform="rotate(90 47.3086 0)" fill="#018837"/>
      <rect x="39.2324" width="28" height="6.07523" rx="3.03762" transform="rotate(90 39.2324 0)" fill="#018837"/>
      <rect x="31.1582" width="28" height="6.07523" rx="3.03762" transform="rotate(90 31.1582 0)" fill="#018837"/>
      <rect x="23.082" width="28" height="6.07523" rx="3.03762" transform="rotate(90 23.082 0)" fill="#018837"/>
      <rect x="15.0078" width="28" height="6.07523" rx="3.03762" transform="rotate(90 15.0078 0)" fill="#018837"/>
      <path d="M3.89403 -1.32778e-07C5.57165 -5.94469e-08 6.93164 1.35999 6.93164 3.03761L6.93164 24.9624C6.93164 26.64 5.57165 28 3.89402 28C2.2164 28 0.856409 26.64 0.856409 24.9624L0.85641 3.03761C0.85641 1.35999 2.2164 -2.0611e-07 3.89403 -1.32778e-07Z" fill="#018837"/>
    </svg>
    <svg width="125" height="28" viewBox="0 0 125 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="violations==11">
      <rect x="124.377" y="4.11523" width="19.7712" height="10.4671" rx="3.88634" transform="rotate(90 124.377 4.11523)" fill="#D9D9D9"/>
      <rect x="111.91" width="28" height="6.07523" rx="3.03762" transform="rotate(90 111.91 0)" fill="#D9D9D9"/>
      <rect x="103.834" width="28" height="6.07523" rx="3.03762" transform="rotate(90 103.834 0)" fill="#D9D9D9"/>
      <rect x="95.7598" width="28" height="6.07523" rx="3.03762" transform="rotate(90 95.7598 0)" fill="#D9D9D9"/>
      <rect x="87.6836" width="28" height="6.07523" rx="3.03762" transform="rotate(90 87.6836 0)" fill="#018837"/>
      <rect x="79.6094" width="28" height="6.07523" rx="3.03762" transform="rotate(90 79.6094 0)" fill="#018837"/>
      <rect x="71.5332" width="28" height="6.07523" rx="3.03762" transform="rotate(90 71.5332 0)" fill="#018837"/>
      <rect x="63.459" width="28" height="6.07523" rx="3.03762" transform="rotate(90 63.459 0)" fill="#018837"/>
      <rect x="55.3828" width="28" height="6.07523" rx="3.03762" transform="rotate(90 55.3828 0)" fill="#018837"/>
      <rect x="47.3086" width="28" height="6.07523" rx="3.03762" transform="rotate(90 47.3086 0)" fill="#018837"/>
      <rect x="39.2324" width="28" height="6.07523" rx="3.03762" transform="rotate(90 39.2324 0)" fill="#018837"/>
      <rect x="31.1582" width="28" height="6.07523" rx="3.03762" transform="rotate(90 31.1582 0)" fill="#018837"/>
      <rect x="23.082" width="28" height="6.07523" rx="3.03762" transform="rotate(90 23.082 0)" fill="#018837"/>
      <rect x="15.0078" width="28" height="6.07523" rx="3.03762" transform="rotate(90 15.0078 0)" fill="#018837"/>
      <path d="M3.89403 -1.32778e-07C5.57165 -5.94469e-08 6.93164 1.35999 6.93164 3.03761L6.93164 24.9624C6.93164 26.64 5.57165 28 3.89402 28C2.2164 28 0.856409 26.64 0.856409 24.9624L0.85641 3.03761C0.85641 1.35999 2.2164 -2.0611e-07 3.89403 -1.32778e-07Z" fill="#018837"/>
    </svg>
    <svg width="125" height="28" viewBox="0 0 125 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="violations==10">
      <rect x="124.377" y="4.11523" width="19.7712" height="10.4671" rx="3.88634" transform="rotate(90 124.377 4.11523)" fill="#D9D9D9"/>
      <rect x="111.91" width="28" height="6.07523" rx="3.03762" transform="rotate(90 111.91 0)" fill="#D9D9D9"/>
      <rect x="103.834" width="28" height="6.07523" rx="3.03762" transform="rotate(90 103.834 0)" fill="#D9D9D9"/>
      <rect x="95.7598" width="28" height="6.07523" rx="3.03762" transform="rotate(90 95.7598 0)" fill="#D9D9D9"/>
      <rect x="87.6836" width="28" height="6.07523" rx="3.03762" transform="rotate(90 87.6836 0)" fill="#D9D9D9"/>
      <rect x="79.6094" width="28" height="6.07523" rx="3.03762" transform="rotate(90 79.6094 0)" fill="#FAD059"/>
      <rect x="71.5332" width="28" height="6.07523" rx="3.03762" transform="rotate(90 71.5332 0)" fill="#FAD059"/>
      <rect x="63.459" width="28" height="6.07523" rx="3.03762" transform="rotate(90 63.459 0)" fill="#FAD059"/>
      <rect x="55.3828" width="28" height="6.07523" rx="3.03762" transform="rotate(90 55.3828 0)" fill="#FAD059"/>
      <rect x="47.3086" width="28" height="6.07523" rx="3.03762" transform="rotate(90 47.3086 0)" fill="#FAD059"/>
      <rect x="39.2324" width="28" height="6.07523" rx="3.03762" transform="rotate(90 39.2324 0)" fill="#FAD059"/>
      <rect x="31.1582" width="28" height="6.07523" rx="3.03762" transform="rotate(90 31.1582 0)" fill="#FAD059"/>
      <rect x="23.082" width="28" height="6.07523" rx="3.03762" transform="rotate(90 23.082 0)" fill="#FAD059"/>
      <rect x="15.0078" width="28" height="6.07523" rx="3.03762" transform="rotate(90 15.0078 0)" fill="#FAD059"/>
      <path d="M3.89403 -1.32778e-07C5.57165 -5.94469e-08 6.93164 1.35999 6.93164 3.03761L6.93164 24.9624C6.93164 26.64 5.57165 28 3.89402 28C2.2164 28 0.856409 26.64 0.856409 24.9624L0.85641 3.03761C0.85641 1.35999 2.2164 -2.0611e-07 3.89403 -1.32778e-07Z" fill="#FAD059"/>
    </svg>
    <svg width="125" height="28" viewBox="0 0 125 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="violations==9">
      <rect x="124.377" y="4.11523" width="19.7712" height="10.4671" rx="3.88634" transform="rotate(90 124.377 4.11523)" fill="#D9D9D9"/>
      <rect x="111.91" width="28" height="6.07523" rx="3.03762" transform="rotate(90 111.91 0)" fill="#D9D9D9"/>
      <rect x="103.834" width="28" height="6.07523" rx="3.03762" transform="rotate(90 103.834 0)" fill="#D9D9D9"/>
      <rect x="95.7598" width="28" height="6.07523" rx="3.03762" transform="rotate(90 95.7598 0)" fill="#D9D9D9"/>
      <rect x="87.6836" width="28" height="6.07523" rx="3.03762" transform="rotate(90 87.6836 0)" fill="#D9D9D9"/>
      <rect x="79.6094" width="28" height="6.07523" rx="3.03762" transform="rotate(90 79.6094 0)" fill="#D9D9D9"/>
      <rect x="71.5332" width="28" height="6.07523" rx="3.03762" transform="rotate(90 71.5332 0)" fill="#FAD059"/>
      <rect x="63.459" width="28" height="6.07523" rx="3.03762" transform="rotate(90 63.459 0)" fill="#FAD059"/>
      <rect x="55.3828" width="28" height="6.07523" rx="3.03762" transform="rotate(90 55.3828 0)" fill="#FAD059"/>
      <rect x="47.3086" width="28" height="6.07523" rx="3.03762" transform="rotate(90 47.3086 0)" fill="#FAD059"/>
      <rect x="39.2324" width="28" height="6.07523" rx="3.03762" transform="rotate(90 39.2324 0)" fill="#FAD059"/>
      <rect x="31.1582" width="28" height="6.07523" rx="3.03762" transform="rotate(90 31.1582 0)" fill="#FAD059"/>
      <rect x="23.082" width="28" height="6.07523" rx="3.03762" transform="rotate(90 23.082 0)" fill="#FAD059"/>
      <rect x="15.0078" width="28" height="6.07523" rx="3.03762" transform="rotate(90 15.0078 0)" fill="#FAD059"/>
      <path d="M3.89403 -1.32778e-07C5.57165 -5.94469e-08 6.93164 1.35999 6.93164 3.03761L6.93164 24.9624C6.93164 26.64 5.57165 28 3.89402 28C2.2164 28 0.856409 26.64 0.856409 24.9624L0.85641 3.03761C0.85641 1.35999 2.2164 -2.0611e-07 3.89403 -1.32778e-07Z" fill="#FAD059"/>
    </svg>
    <svg width="125" height="28" viewBox="0 0 125 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="violations==8">
      <rect x="124.377" y="4.11523" width="19.7712" height="10.4671" rx="3.88634" transform="rotate(90 124.377 4.11523)" fill="#D9D9D9"/>
      <rect x="111.91" width="28" height="6.07523" rx="3.03762" transform="rotate(90 111.91 0)" fill="#D9D9D9"/>
      <rect x="103.834" width="28" height="6.07523" rx="3.03762" transform="rotate(90 103.834 0)" fill="#D9D9D9"/>
      <rect x="95.7598" width="28" height="6.07523" rx="3.03762" transform="rotate(90 95.7598 0)" fill="#D9D9D9"/>
      <rect x="87.6836" width="28" height="6.07523" rx="3.03762" transform="rotate(90 87.6836 0)" fill="#D9D9D9"/>
      <rect x="79.6094" width="28" height="6.07523" rx="3.03762" transform="rotate(90 79.6094 0)" fill="#D9D9D9"/>
      <rect x="71.5332" width="28" height="6.07523" rx="3.03762" transform="rotate(90 71.5332 0)" fill="#D9D9D9"/>
      <rect x="63.459" width="28" height="6.07523" rx="3.03762" transform="rotate(90 63.459 0)" fill="#FAD059"/>
      <rect x="55.3828" width="28" height="6.07523" rx="3.03762" transform="rotate(90 55.3828 0)" fill="#FAD059"/>
      <rect x="47.3086" width="28" height="6.07523" rx="3.03762" transform="rotate(90 47.3086 0)" fill="#FAD059"/>
      <rect x="39.2324" width="28" height="6.07523" rx="3.03762" transform="rotate(90 39.2324 0)" fill="#FAD059"/>
      <rect x="31.1582" width="28" height="6.07523" rx="3.03762" transform="rotate(90 31.1582 0)" fill="#FAD059"/>
      <rect x="23.082" width="28" height="6.07523" rx="3.03762" transform="rotate(90 23.082 0)" fill="#FAD059"/>
      <rect x="15.0078" width="28" height="6.07523" rx="3.03762" transform="rotate(90 15.0078 0)" fill="#FAD059"/>
      <path d="M3.89403 -1.32778e-07C5.57165 -5.94469e-08 6.93164 1.35999 6.93164 3.03761L6.93164 24.9624C6.93164 26.64 5.57165 28 3.89402 28C2.2164 28 0.856409 26.64 0.856409 24.9624L0.85641 3.03761C0.85641 1.35999 2.2164 -2.0611e-07 3.89403 -1.32778e-07Z" fill="#FAD059"/>
    </svg>
    <svg width="125" height="28" viewBox="0 0 125 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="violations==7">
      <rect x="124.377" y="4.11523" width="19.7712" height="10.4671" rx="3.88634" transform="rotate(90 124.377 4.11523)" fill="#D9D9D9"/>
      <rect x="111.91" width="28" height="6.07523" rx="3.03762" transform="rotate(90 111.91 0)" fill="#D9D9D9"/>
      <rect x="103.834" width="28" height="6.07523" rx="3.03762" transform="rotate(90 103.834 0)" fill="#D9D9D9"/>
      <rect x="95.7598" width="28" height="6.07523" rx="3.03762" transform="rotate(90 95.7598 0)" fill="#D9D9D9"/>
      <rect x="87.6836" width="28" height="6.07523" rx="3.03762" transform="rotate(90 87.6836 0)" fill="#D9D9D9"/>
      <rect x="79.6094" width="28" height="6.07523" rx="3.03762" transform="rotate(90 79.6094 0)" fill="#D9D9D9"/>
      <rect x="71.5332" width="28" height="6.07523" rx="3.03762" transform="rotate(90 71.5332 0)" fill="#D9D9D9"/>
      <rect x="63.459" width="28" height="6.07523" rx="3.03762" transform="rotate(90 63.459 0)" fill="#D9D9D9"/>
      <rect x="55.3828" width="28" height="6.07523" rx="3.03762" transform="rotate(90 55.3828 0)" fill="#FAD059"/>
      <rect x="47.3086" width="28" height="6.07523" rx="3.03762" transform="rotate(90 47.3086 0)" fill="#FAD059"/>
      <rect x="39.2324" width="28" height="6.07523" rx="3.03762" transform="rotate(90 39.2324 0)" fill="#FAD059"/>
      <rect x="31.1582" width="28" height="6.07523" rx="3.03762" transform="rotate(90 31.1582 0)" fill="#FAD059"/>
      <rect x="23.082" width="28" height="6.07523" rx="3.03762" transform="rotate(90 23.082 0)" fill="#FAD059"/>
      <rect x="15.0078" width="28" height="6.07523" rx="3.03762" transform="rotate(90 15.0078 0)" fill="#FAD059"/>
      <path d="M3.89403 -1.32778e-07C5.57165 -5.94469e-08 6.93164 1.35999 6.93164 3.03761L6.93164 24.9624C6.93164 26.64 5.57165 28 3.89402 28C2.2164 28 0.856409 26.64 0.856409 24.9624L0.85641 3.03761C0.85641 1.35999 2.2164 -2.0611e-07 3.89403 -1.32778e-07Z" fill="#FAD059"/>
    </svg>
    <svg width="125" height="28" viewBox="0 0 125 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="violations==6">
      <rect x="124.377" y="4.11523" width="19.7712" height="10.4671" rx="3.88634" transform="rotate(90 124.377 4.11523)" fill="#D9D9D9"/>
      <rect x="111.91" width="28" height="6.07523" rx="3.03762" transform="rotate(90 111.91 0)" fill="#D9D9D9"/>
      <rect x="103.834" width="28" height="6.07523" rx="3.03762" transform="rotate(90 103.834 0)" fill="#D9D9D9"/>
      <rect x="95.7598" width="28" height="6.07523" rx="3.03762" transform="rotate(90 95.7598 0)" fill="#D9D9D9"/>
      <rect x="87.6836" width="28" height="6.07523" rx="3.03762" transform="rotate(90 87.6836 0)" fill="#D9D9D9"/>
      <rect x="79.6094" width="28" height="6.07523" rx="3.03762" transform="rotate(90 79.6094 0)" fill="#D9D9D9"/>
      <rect x="71.5332" width="28" height="6.07523" rx="3.03762" transform="rotate(90 71.5332 0)" fill="#D9D9D9"/>
      <rect x="63.459" width="28" height="6.07523" rx="3.03762" transform="rotate(90 63.459 0)" fill="#D9D9D9"/>
      <rect x="55.3828" width="28" height="6.07523" rx="3.03762" transform="rotate(90 55.3828 0)" fill="#D9D9D9"/>
      <rect x="47.3086" width="28" height="6.07523" rx="3.03762" transform="rotate(90 47.3086 0)" fill="#FAD059"/>
      <rect x="39.2324" width="28" height="6.07523" rx="3.03762" transform="rotate(90 39.2324 0)" fill="#FAD059"/>
      <rect x="31.1582" width="28" height="6.07523" rx="3.03762" transform="rotate(90 31.1582 0)" fill="#FAD059"/>
      <rect x="23.082" width="28" height="6.07523" rx="3.03762" transform="rotate(90 23.082 0)" fill="#FAD059"/>
      <rect x="15.0078" width="28" height="6.07523" rx="3.03762" transform="rotate(90 15.0078 0)" fill="#FAD059"/>
      <path d="M3.89403 -1.32778e-07C5.57165 -5.94469e-08 6.93164 1.35999 6.93164 3.03761L6.93164 24.9624C6.93164 26.64 5.57165 28 3.89402 28C2.2164 28 0.856409 26.64 0.856409 24.9624L0.85641 3.03761C0.85641 1.35999 2.2164 -2.0611e-07 3.89403 -1.32778e-07Z" fill="#FAD059"/>
    </svg>
    <svg width="125" height="28" viewBox="0 0 125 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="violations==5">
      <rect x="124.377" y="4.11523" width="19.7712" height="10.4671" rx="3.88634" transform="rotate(90 124.377 4.11523)" fill="#D9D9D9"/>
      <rect x="111.91" width="28" height="6.07523" rx="3.03762" transform="rotate(90 111.91 0)" fill="#D9D9D9"/>
      <rect x="103.834" width="28" height="6.07523" rx="3.03762" transform="rotate(90 103.834 0)" fill="#D9D9D9"/>
      <rect x="95.7598" width="28" height="6.07523" rx="3.03762" transform="rotate(90 95.7598 0)" fill="#D9D9D9"/>
      <rect x="87.6836" width="28" height="6.07523" rx="3.03762" transform="rotate(90 87.6836 0)" fill="#D9D9D9"/>
      <rect x="79.6094" width="28" height="6.07523" rx="3.03762" transform="rotate(90 79.6094 0)" fill="#D9D9D9"/>
      <rect x="71.5332" width="28" height="6.07523" rx="3.03762" transform="rotate(90 71.5332 0)" fill="#D9D9D9"/>
      <rect x="63.459" width="28" height="6.07523" rx="3.03762" transform="rotate(90 63.459 0)" fill="#D9D9D9"/>
      <rect x="55.3828" width="28" height="6.07523" rx="3.03762" transform="rotate(90 55.3828 0)" fill="#D9D9D9"/>
      <rect x="47.3086" width="28" height="6.07523" rx="3.03762" transform="rotate(90 47.3086 0)" fill="#D9D9D9"/>
      <rect x="39.2324" width="28" height="6.07523" rx="3.03762" transform="rotate(90 39.2324 0)" fill="#E24C4B"/>
      <rect x="31.1582" width="28" height="6.07523" rx="3.03762" transform="rotate(90 31.1582 0)" fill="#E24C4B"/>
      <rect x="23.082" width="28" height="6.07523" rx="3.03762" transform="rotate(90 23.082 0)" fill="#E24C4B"/>
      <rect x="15.0078" width="28" height="6.07523" rx="3.03762" transform="rotate(90 15.0078 0)" fill="#E24C4B"/>
      <path d="M3.89403 -1.32778e-07C5.57165 -5.94469e-08 6.93164 1.35999 6.93164 3.03761L6.93164 24.9624C6.93164 26.64 5.57165 28 3.89402 28C2.2164 28 0.856409 26.64 0.856409 24.9624L0.85641 3.03761C0.85641 1.35999 2.2164 -2.0611e-07 3.89403 -1.32778e-07Z" fill="#E24C4B"/>
    </svg>
    <svg width="125" height="28" viewBox="0 0 125 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="violations==4">
      <rect x="124.377" y="4.11523" width="19.7712" height="10.4671" rx="3.88634" transform="rotate(90 124.377 4.11523)" fill="#D9D9D9"/>
      <rect x="111.91" width="28" height="6.07523" rx="3.03762" transform="rotate(90 111.91 0)" fill="#D9D9D9"/>
      <rect x="103.834" width="28" height="6.07523" rx="3.03762" transform="rotate(90 103.834 0)" fill="#D9D9D9"/>
      <rect x="95.7598" width="28" height="6.07523" rx="3.03762" transform="rotate(90 95.7598 0)" fill="#D9D9D9"/>
      <rect x="87.6836" width="28" height="6.07523" rx="3.03762" transform="rotate(90 87.6836 0)" fill="#D9D9D9"/>
      <rect x="79.6094" width="28" height="6.07523" rx="3.03762" transform="rotate(90 79.6094 0)" fill="#D9D9D9"/>
      <rect x="71.5332" width="28" height="6.07523" rx="3.03762" transform="rotate(90 71.5332 0)" fill="#D9D9D9"/>
      <rect x="63.459" width="28" height="6.07523" rx="3.03762" transform="rotate(90 63.459 0)" fill="#D9D9D9"/>
      <rect x="55.3828" width="28" height="6.07523" rx="3.03762" transform="rotate(90 55.3828 0)" fill="#D9D9D9"/>
      <rect x="47.3086" width="28" height="6.07523" rx="3.03762" transform="rotate(90 47.3086 0)" fill="#D9D9D9"/>
      <rect x="39.2324" width="28" height="6.07523" rx="3.03762" transform="rotate(90 39.2324 0)" fill="#D9D9D9"/>
      <rect x="31.1582" width="28" height="6.07523" rx="3.03762" transform="rotate(90 31.1582 0)" fill="#E24C4B"/>
      <rect x="23.082" width="28" height="6.07523" rx="3.03762" transform="rotate(90 23.082 0)" fill="#E24C4B"/>
      <rect x="15.0078" width="28" height="6.07523" rx="3.03762" transform="rotate(90 15.0078 0)" fill="#E24C4B"/>
      <path d="M3.89403 -1.32778e-07C5.57165 -5.94469e-08 6.93164 1.35999 6.93164 3.03761L6.93164 24.9624C6.93164 26.64 5.57165 28 3.89402 28C2.2164 28 0.856409 26.64 0.856409 24.9624L0.85641 3.03761C0.85641 1.35999 2.2164 -2.0611e-07 3.89403 -1.32778e-07Z" fill="#E24C4B"/>
    </svg>
    <svg width="125" height="28" viewBox="0 0 125 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="violations==3">
      <rect x="124.377" y="4.11523" width="19.7712" height="10.4671" rx="3.88634" transform="rotate(90 124.377 4.11523)" fill="#D9D9D9"/>
      <rect x="111.91" width="28" height="6.07523" rx="3.03762" transform="rotate(90 111.91 0)" fill="#D9D9D9"/>
      <rect x="103.834" width="28" height="6.07523" rx="3.03762" transform="rotate(90 103.834 0)" fill="#D9D9D9"/>
      <rect x="95.7598" width="28" height="6.07523" rx="3.03762" transform="rotate(90 95.7598 0)" fill="#D9D9D9"/>
      <rect x="87.6836" width="28" height="6.07523" rx="3.03762" transform="rotate(90 87.6836 0)" fill="#D9D9D9"/>
      <rect x="79.6094" width="28" height="6.07523" rx="3.03762" transform="rotate(90 79.6094 0)" fill="#D9D9D9"/>
      <rect x="71.5332" width="28" height="6.07523" rx="3.03762" transform="rotate(90 71.5332 0)" fill="#D9D9D9"/>
      <rect x="63.459" width="28" height="6.07523" rx="3.03762" transform="rotate(90 63.459 0)" fill="#D9D9D9"/>
      <rect x="55.3828" width="28" height="6.07523" rx="3.03762" transform="rotate(90 55.3828 0)" fill="#D9D9D9"/>
      <rect x="47.3086" width="28" height="6.07523" rx="3.03762" transform="rotate(90 47.3086 0)" fill="#D9D9D9"/>
      <rect x="39.2324" width="28" height="6.07523" rx="3.03762" transform="rotate(90 39.2324 0)" fill="#D9D9D9"/>
      <rect x="31.1582" width="28" height="6.07523" rx="3.03762" transform="rotate(90 31.1582 0)" fill="#D9D9D9"/>
      <rect x="23.082" width="28" height="6.07523" rx="3.03762" transform="rotate(90 23.082 0)" fill="#E24C4B"/>
      <rect x="15.0078" width="28" height="6.07523" rx="3.03762" transform="rotate(90 15.0078 0)" fill="#E24C4B"/>
      <path d="M3.89403 -1.32778e-07C5.57165 -5.94469e-08 6.93164 1.35999 6.93164 3.03761L6.93164 24.9624C6.93164 26.64 5.57165 28 3.89402 28C2.2164 28 0.856409 26.64 0.856409 24.9624L0.85641 3.03761C0.85641 1.35999 2.2164 -2.0611e-07 3.89403 -1.32778e-07Z" fill="#E24C4B"/>
    </svg>
    <svg width="125" height="28" viewBox="0 0 125 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="violations==2">
      <rect x="124.377" y="4.11523" width="19.7712" height="10.4671" rx="3.88634" transform="rotate(90 124.377 4.11523)" fill="#D9D9D9"/>
      <rect x="111.91" width="28" height="6.07523" rx="3.03762" transform="rotate(90 111.91 0)" fill="#D9D9D9"/>
      <rect x="103.834" width="28" height="6.07523" rx="3.03762" transform="rotate(90 103.834 0)" fill="#D9D9D9"/>
      <rect x="95.7598" width="28" height="6.07523" rx="3.03762" transform="rotate(90 95.7598 0)" fill="#D9D9D9"/>
      <rect x="87.6836" width="28" height="6.07523" rx="3.03762" transform="rotate(90 87.6836 0)" fill="#D9D9D9"/>
      <rect x="79.6094" width="28" height="6.07523" rx="3.03762" transform="rotate(90 79.6094 0)" fill="#D9D9D9"/>
      <rect x="71.5332" width="28" height="6.07523" rx="3.03762" transform="rotate(90 71.5332 0)" fill="#D9D9D9"/>
      <rect x="63.459" width="28" height="6.07523" rx="3.03762" transform="rotate(90 63.459 0)" fill="#D9D9D9"/>
      <rect x="55.3828" width="28" height="6.07523" rx="3.03762" transform="rotate(90 55.3828 0)" fill="#D9D9D9"/>
      <rect x="47.3086" width="28" height="6.07523" rx="3.03762" transform="rotate(90 47.3086 0)" fill="#D9D9D9"/>
      <rect x="39.2324" width="28" height="6.07523" rx="3.03762" transform="rotate(90 39.2324 0)" fill="#D9D9D9"/>
      <rect x="31.1582" width="28" height="6.07523" rx="3.03762" transform="rotate(90 31.1582 0)" fill="#D9D9D9"/>
      <rect x="23.082" width="28" height="6.07523" rx="3.03762" transform="rotate(90 23.082 0)" fill="#D9D9D9"/>
      <rect x="15.0078" width="28" height="6.07523" rx="3.03762" transform="rotate(90 15.0078 0)" fill="#E24C4B"/>
      <path d="M3.89403 -1.32778e-07C5.57165 -5.94469e-08 6.93164 1.35999 6.93164 3.03761L6.93164 24.9624C6.93164 26.64 5.57165 28 3.89402 28C2.2164 28 0.856409 26.64 0.856409 24.9624L0.85641 3.03761C0.85641 1.35999 2.2164 -2.0611e-07 3.89403 -1.32778e-07Z" fill="#E24C4B"/>
    </svg>
    <svg width="125" height="28" viewBox="0 0 125 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="violations==1">
      <rect x="124.377" y="4.11523" width="19.7712" height="10.4671" rx="3.88634" transform="rotate(90 124.377 4.11523)" fill="#D9D9D9"/>
      <rect x="111.91" width="28" height="6.07523" rx="3.03762" transform="rotate(90 111.91 0)" fill="#D9D9D9"/>
      <rect x="103.834" width="28" height="6.07523" rx="3.03762" transform="rotate(90 103.834 0)" fill="#D9D9D9"/>
      <rect x="95.7598" width="28" height="6.07523" rx="3.03762" transform="rotate(90 95.7598 0)" fill="#D9D9D9"/>
      <rect x="87.6836" width="28" height="6.07523" rx="3.03762" transform="rotate(90 87.6836 0)" fill="#D9D9D9"/>
      <rect x="79.6094" width="28" height="6.07523" rx="3.03762" transform="rotate(90 79.6094 0)" fill="#D9D9D9"/>
      <rect x="71.5332" width="28" height="6.07523" rx="3.03762" transform="rotate(90 71.5332 0)" fill="#D9D9D9"/>
      <rect x="63.459" width="28" height="6.07523" rx="3.03762" transform="rotate(90 63.459 0)" fill="#D9D9D9"/>
      <rect x="55.3828" width="28" height="6.07523" rx="3.03762" transform="rotate(90 55.3828 0)" fill="#D9D9D9"/>
      <rect x="47.3086" width="28" height="6.07523" rx="3.03762" transform="rotate(90 47.3086 0)" fill="#D9D9D9"/>
      <rect x="39.2324" width="28" height="6.07523" rx="3.03762" transform="rotate(90 39.2324 0)" fill="#D9D9D9"/>
      <rect x="31.1582" width="28" height="6.07523" rx="3.03762" transform="rotate(90 31.1582 0)" fill="#D9D9D9"/>
      <rect x="23.082" width="28" height="6.07523" rx="3.03762" transform="rotate(90 23.082 0)" fill="#D9D9D9"/>
      <rect x="15.0078" width="28" height="6.07523" rx="3.03762" transform="rotate(90 15.0078 0)" fill="#D9D9D9"/>
      <path d="M3.89403 -1.32778e-07C5.57165 -5.94469e-08 6.93164 1.35999 6.93164 3.03761L6.93164 24.9624C6.93164 26.64 5.57165 28 3.89402 28C2.2164 28 0.856409 26.64 0.856409 24.9624L0.85641 3.03761C0.85641 1.35999 2.2164 -2.0611e-07 3.89403 -1.32778e-07Z" fill="#E24C4B"/>
    </svg>
    <svg width="125" height="28" viewBox="0 0 125 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="violations<1">
      <rect x="124.377" y="4.11523" width="19.7712" height="10.4671" rx="3.88634" transform="rotate(90 124.377 4.11523)" fill="#D9D9D9"/>
      <rect x="111.91" width="28" height="6.07523" rx="3.03762" transform="rotate(90 111.91 0)" fill="#D9D9D9"/>
      <rect x="103.834" width="28" height="6.07523" rx="3.03762" transform="rotate(90 103.834 0)" fill="#D9D9D9"/>
      <rect x="95.7598" width="28" height="6.07523" rx="3.03762" transform="rotate(90 95.7598 0)" fill="#D9D9D9"/>
      <rect x="87.6836" width="28" height="6.07523" rx="3.03762" transform="rotate(90 87.6836 0)" fill="#D9D9D9"/>
      <rect x="79.6094" width="28" height="6.07523" rx="3.03762" transform="rotate(90 79.6094 0)" fill="#D9D9D9"/>
      <rect x="71.5332" width="28" height="6.07523" rx="3.03762" transform="rotate(90 71.5332 0)" fill="#D9D9D9"/>
      <rect x="63.459" width="28" height="6.07523" rx="3.03762" transform="rotate(90 63.459 0)" fill="#D9D9D9"/>
      <rect x="55.3828" width="28" height="6.07523" rx="3.03762" transform="rotate(90 55.3828 0)" fill="#D9D9D9"/>
      <rect x="47.3086" width="28" height="6.07523" rx="3.03762" transform="rotate(90 47.3086 0)" fill="#D9D9D9"/>
      <rect x="39.2324" width="28" height="6.07523" rx="3.03762" transform="rotate(90 39.2324 0)" fill="#D9D9D9"/>
      <rect x="31.1582" width="28" height="6.07523" rx="3.03762" transform="rotate(90 31.1582 0)" fill="#D9D9D9"/>
      <rect x="23.082" width="28" height="6.07523" rx="3.03762" transform="rotate(90 23.082 0)" fill="#D9D9D9"/>
      <rect x="15.0078" width="28" height="6.07523" rx="3.03762" transform="rotate(90 15.0078 0)" fill="#D9D9D9"/>
      <path d="M3.89403 -1.32778e-07C5.57165 -5.94469e-08 6.93164 1.35999 6.93164 3.03761L6.93164 24.9624C6.93164 26.64 5.57165 28 3.89402 28C2.2164 28 0.856409 26.64 0.856409 24.9624L0.85641 3.03761C0.85641 1.35999 2.2164 -2.0611e-07 3.89403 -1.32778e-07Z" fill="#D9D9D9"/>
    </svg>

    <div class="violations-label">
      {{violations}}/15
    </div>


  </div>

</template>

<script>
export default {
  name: "BatteryIndicator",
  props: {
    violations: {
      type: Number,
      required: true
    }
  },
}
</script>

<style scoped>

</style>